import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AESEncryptDecryptService {

  // secretKey = "BKGsN85lqJB0Slf5hcAKBkIkAWCYG5KrKCtWkgUrLGD=";
      secretKey = 'BKGsN85lqJB0Slf5';
      encryptedBase64Key = 'bXVzdGJlMTZieXRlc2tleQ==';
      parsedBase64Key = CryptoJS.enc.Base64.parse(this.encryptedBase64Key);
      substitutionsBeforeDecryption: Map<string, string> = new Map([
        ['-', '+'],
        ['_', '/'],
        ['=', '~'],
    ]);
  constructor() { }

  // encrypt(value : string) : string{
  //   return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  // }

  // decrypt(textToDecrypt : string){
  //   return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
  // }

  //  encryptedData = null;

  // encryptedCipherText = 'U2WvSc8oTur1KkrB6VGNDmA3XxJb9cC+T9RnqT4kD90='; // or encryptedData;
  
  // console.log( “DecryptedData = “ + decryptedData );

  // this is the decrypted data as a string
  decrypt(encryptedCipherText:any){
    const toDecrypt = encryptedCipherText.replace(
      /[-_~]/g,
      (match: string) => this.substitutionsBeforeDecryption.get(match) ?? match
  );
    let decryptedData = CryptoJS.AES.decrypt(toDecrypt, this.parsedBase64Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
      });
    return decryptedData.toString( CryptoJS.enc.Utf8 );
  }

  encrypt(plaintText:any){
    let encryptedData = CryptoJS.AES.encrypt(plaintText, this.parsedBase64Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
      });
      return encodeURIComponent(encryptedData.toString());
  }
 
  // console.log( "DecryptedText = " + decryptedText );
  
}