import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class Utils {
  constructor() {}

  percentage(percent: number, total: number) {
    return Number(((percent / 100) * total).toFixed(2));
  }

  validateProperty(property: string, validators: ValidatorFn[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const propertyVal = control.value && control.value[property];
      const newFc = new FormControl(propertyVal);
      const failedValidators = validators
        .map((v) => v(newFc))
        .filter((v) => !!v);
      return failedValidators.length
        ? { invalidProperty: failedValidators }
        : null;
    };
  }
}
