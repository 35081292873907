import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Loading } from 'notiflix';
import { catchError, EMPTY, Observable, switchMap, tap } from 'rxjs';
import { SEARCH_MENU_ITEMS_PAYLOAD } from 'src/app/shared/constants';
import { ResponseType } from '../../../shared/store/shared.state';
import { MenuService } from '../service/menu.service';
import { InitialMenuState, MenuState } from './menu.state';

@Injectable()
export class MenuStore extends ComponentStore<MenuState> {
  constructor(private menuService: MenuService) {
    super(InitialMenuState);
  }

  activeMenuOptions$ = this.select((state) => state.activeMenuOptions);
  activeCategories$ = this.select((state) => state.activeCategories);
  activeDishTypes$ = this.select((state) => state.activeDishTypes);
  activeMealTypes$ = this.select((state) => state.activeMealTypes);
  menuDetails$ = this.select((state) => state.menuDetails);
  menuItems$ = this.select((state) => state.menuItems);
  itemDetails$ = this.select((state) => state.itemDetails);

  //**** UPDATERS */
  readonly updateMenuItems = this.updater((state, value: ResponseType) => ({
    ...state,
    menuItems: value,
  }));
  readonly updateMenuDetails = this.updater((state, value: ResponseType) => ({
    ...state,
    menuDetails: value,
  }));
  readonly updateActiveMenuOptions = this.updater(
    (state, value: ResponseType) => ({ ...state, activeMenuOptions: value })
  );
  readonly updateActiveCategories = this.updater(
    (state, value: ResponseType) => ({ ...state, activeCategories: value })
  );
  readonly updateActiveDishTypes = this.updater(
    (state, value: ResponseType) => ({ ...state, activeDishTypes: value })
  );
  readonly updateActiveMealTypes = this.updater(
    (state, value: ResponseType) => ({ ...state, activeMealTypes: value })
  );
  readonly updateItemDetails = this.updater((state, value: ResponseType) => ({
    ...state,
    itemDetails: value,
  }));
  //  readonly fetchMenuDetails = this.effect((id$: Observable<string>) => {
  //   return id$.pipe(
  //     tap((_) => this.updateMenuDetails({data: null, loading: true, success: false})),
  //     switchMap(() => )
  //   )
  //  })

  readonly searchMenuItems = this.effect(
    (payload$: Observable<SEARCH_MENU_ITEMS_PAYLOAD>) => {
      return payload$.pipe(
        tap((_: any) => {
          Loading.circle();
          this.updateMenuItems({ data: [], loading: true, success: false });
        }),
        switchMap((payload) =>
          this.menuService.searchMenuItems(payload).pipe(
            tap({
              next: (res: any) => {
                this.updateMenuItems({
                  data: res.data,
                  loading: false,
                  success: true,
                });
                Loading.remove();
              },
            }),
            catchError((err) => {
              this.updateMenuItems({
                data: [],
                loading: false,
                success: false,
              });
              Loading.remove();
              return EMPTY;
            })
          )
        )
      );
    }
  );

  readonly fetchActiveMenuOptions = this.effect((trigger$) => {
    return trigger$.pipe(
      tap((_: any) =>
        this.updateActiveMenuOptions({
          data: [],
          loading: true,
          success: false,
        })
      ),
      switchMap(() =>
        this.menuService.getAllActiveWeeklyMenu().pipe(
          tap({
            next: (res: any) => {
              this.updateActiveMenuOptions({
                data: res.data,
                loading: false,
                success: true,
              });
            },
          }),
          catchError((err) => {
            this.updateActiveMenuOptions({
              data: [],
              loading: false,
              success: false,
            });
            return EMPTY;
          })
        )
      )
    );
  });
  readonly fetchActiveCategories = this.effect((trigger$) => {
    return trigger$.pipe(
      tap((_: any) =>
        this.updateActiveCategories({ data: [], loading: true, success: false })
      ),
      switchMap((payload) =>
        this.menuService.getAllActiveCategory().pipe(
          tap({
            next: (res: any) => {
              this.updateActiveCategories({
                data: res.data,
                loading: false,
                success: true,
              });
            },
          }),
          catchError((err) => {
            this.updateActiveCategories({
              data: [],
              loading: false,
              success: false,
            });
            return EMPTY;
          })
        )
      )
    );
  });

  readonly fetchMenuItemdetails = this.effect(
    (paramMap$: Observable<{ menuOptionSlug: string; itemSlug: string }>) => {
      return paramMap$.pipe(
        tap((_: any) =>
          this.updateMenuDetails({ data: [], loading: true, success: false })
        ),
        switchMap(({ menuOptionSlug, itemSlug }) =>
          this.menuService.getMenuDetailsBySlug(menuOptionSlug, itemSlug).pipe(
            tap({
              next: (res: any) => {
                this.updateMenuDetails({
                  data: res.data,
                  loading: false,
                  success: true,
                });
              },
            }),
            catchError((err) => {
              this.updateMenuDetails({
                data: [],
                loading: false,
                success: false,
              });
              return EMPTY;
            })
          )
        )
      );
    }
  );

  readonly fetchActiveDishTypes = this.effect((trigger$) => {
    return trigger$.pipe(
      tap((_: any) =>
        this.updateActiveDishTypes({ data: [], loading: true, success: false })
      ),
      switchMap((payload) =>
        this.menuService.getAllActiveDishTypes().pipe(
          tap({
            next: (res: any) => {
              this.updateActiveDishTypes({
                data: res.data,
                loading: false,
                success: true,
              });
            },
          }),
          catchError((err) => {
            this.updateActiveDishTypes({
              data: [],
              loading: false,
              success: false,
            });
            return EMPTY;
          })
        )
      )
    );
  });
  readonly fetchActiveMealTypes = this.effect((trigger$) => {
    return trigger$.pipe(
      tap((_: any) =>
        this.updateActiveMealTypes({ data: [], loading: true, success: false })
      ),
      switchMap((payload) =>
        this.menuService.getAllActiveMealTypes().pipe(
          tap({
            next: (res: any) => {
              this.updateActiveMealTypes({
                data: res.data,
                loading: false,
                success: true,
              });
            },
          }),
          catchError((err) => {
            this.updateActiveMealTypes({
              data: [],
              loading: false,
              success: false,
            });
            return EMPTY;
          })
        )
      )
    );
  });

  readonly subscribeTo = this.effect<unknown>(($) => $);
}
