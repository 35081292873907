import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RootAddressDialogComponent } from './components/address-dialog/address-dialog.component';
import { BasicInfoDialogComponent } from './components/basic-info-dialog/basic-info-dialog.component';
import { GratuitySectionComponent } from './components/gratuity-section/gratuity-section.component';
import { MenuCardComponent } from './components/menu-card/menu-card.component';
import { CamelifyPipe } from './pipes/camelify.pipe';
import { DateCstPipe } from './pipes/date-cst.pipe';
import { DateFormatPipe } from './pipes/date.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { NumberPipe } from './pipes/number.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { PrecisePipe } from './pipes/precise.pipe';
import { RoundPipe } from './pipes/round.pipe';
import { TitlecasePipe } from './pipes/titlecase.pipe';
import { RootEffects } from './store/root.effect';
import { RootReducers } from './store/root.reducer';

export const RootFeatureKey = 'root';
@NgModule({
  declarations: [
    EllipsisPipe,
    PhonePipe,
    RootAddressDialogComponent,
    BasicInfoDialogComponent,
    GratuitySectionComponent,
    PhonePipe,
    DateCstPipe,
    MenuCardComponent,
    CamelifyPipe,
    PrecisePipe,
    TitlecasePipe,
    NumberPipe,
    DateFormatPipe,
    RoundPipe,
    DateFormatPipe,
  ],
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    InputMaskModule,
    ButtonModule,
    MessagesModule,
    ReactiveFormsModule,
    InputNumberModule,
    ButtonModule,
    SelectButtonModule,
    FormsModule,
    DropdownModule,
    InputNumberModule,
    StoreModule.forFeature('root', RootReducers),
    EffectsModule.forFeature([RootEffects]),
    InputTextareaModule,
  ],
  exports: [
    EllipsisPipe,
    PhonePipe,
    DateCstPipe,
    CamelifyPipe,
    PrecisePipe,
    TitlecasePipe,
    NumberPipe,
    DateFormatPipe,
    RootAddressDialogComponent,
    BasicInfoDialogComponent,
    MenuCardComponent,
    GratuitySectionComponent,
    DateFormatPipe,
  ],
})
export class SharedModule {}
