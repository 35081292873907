import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserByIdState } from 'src/app/shared/store/root.selector';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
})
export class UserLayoutComponent implements OnInit {
  customerDtl: any = '';
  displayMsg: string = '';
  customerId: string | null;

  constructor(private store: Store) { 
    this.customerId = localStorage.getItem('customerId') || null;
  }
  customer$ = this.store.select(selectUserByIdState)
  ngOnInit(): void {
    this.customer$.subscribe((res:any)=> {
      if(res){
        this.customerDtl = res;
        this.displayMsg = `You are ordering for ${this.customerDtl?.firstName} ${this.customerDtl?.lastName}.`
      }
    })
  }

}
