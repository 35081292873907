import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';

@Pipe({
  name: 'dateCst',
})
export class DateCstPipe implements PipeTransform {
  transform(value: string | null | undefined, localeFormat: string): string {
    if (!value) return '';
    moment.locale();
    const momentDate = momentTz(value);
    return momentDate.tz('America/Chicago').format(localeFormat);
  }
}
