import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, concatMap, map, mergeMap, throwError } from 'rxjs';
import {
  GET_STRIPE_CUSTOMER_ENDPOINT,
  GET_USER_DETAILS_BY_ID,
  SAVE_STRIPE_CUSTOMER_ENDPOINT,
  SIGNUP_PAYLOAD_ALT,
  UPDATE_USER_PAYLOAD,
} from 'src/app/shared/constants';
import {
  FATCH_EMAIL_BY_TOKEN_ENDPOINT,
  FORGET_PASSWORD_ENDPOINT,
  GENERATE_TOKEN,
  GENERATE_TOKEN_PAYLOAD,
  GET_USER_DETAILS,
  SIGNUP_PAYLOAD,
  SIGN_UP_ENDPOINT,
  UPDATE_PASSWORD_BY_ID,
  UPDATE_USER_DETAILS_ENDPOINT,
} from '../constants';
import { SIGN_UP_ENDPOINT_ALT } from './../constants';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  jwtHelper: JwtHelperService;
  constructor(private httpService: HttpService, private router: Router) {
    this.jwtHelper = new JwtHelperService();
  }

  getUserToken(): string | undefined | null {
    const token = localStorage.getItem('jwt');
    return token;
  }

  getAuthenticatedUserId() {
    return localStorage.getItem('customerId')
      ? localStorage.getItem('customerId')
      : localStorage.getItem('id');
  }

  getAuthenticatedUserRole() {
    return localStorage.getItem('role');
  }

  generateToken(payload: GENERATE_TOKEN_PAYLOAD) {
    return this.httpService.post(GENERATE_TOKEN, payload);
  }

  getUserDetails() {
    return this.httpService.get(GET_USER_DETAILS);
  }

  getUserDetailsById(userId: any) {
    return this.httpService.get(
      GET_USER_DETAILS_BY_ID.replace('<user_id>', userId)
    );
  }

  forgetpassword(payload: any) {
    return this.httpService.post(FORGET_PASSWORD_ENDPOINT, payload);
  }
  updatepassword(payload: any) {
    return this.httpService.post(UPDATE_PASSWORD_BY_ID, payload);
  }
  fatchemailbytoken(token: any) {
    return this.httpService.get(FATCH_EMAIL_BY_TOKEN_ENDPOINT + token);
  }

  signup(payload: SIGNUP_PAYLOAD) {
    return this.httpService.post(SIGN_UP_ENDPOINT, payload);
  }
  register(payload: SIGNUP_PAYLOAD_ALT) {
    return this.httpService.post(SIGN_UP_ENDPOINT_ALT, payload);
  }

  saveStripeCustomer(userId: any) {
    const data = {
      id: userId
    }
    return this.httpService.post(SAVE_STRIPE_CUSTOMER_ENDPOINT, data);
  }
  
  getStripeCustomer(userId:any) {
    return this.httpService.get(GET_STRIPE_CUSTOMER_ENDPOINT.replace("<userId>", userId));
  }

  login(
    payload: GENERATE_TOKEN_PAYLOAD,
    backUrl: string | null = null,
    userId?: any
  ) {
    const response$ = this.generateToken(payload).pipe(
      map((res: any) => {
        const resData = res.data;
        localStorage.setItem('jwt', resData);
        return res;
      }),
      catchError((err) => {
        console.log('err cauth: ', err);
        return throwError(() => err);
      }),
      mergeMap((res) => this.getUserDetails()),
      map((res: any) => {
        const resData = res.data;
        localStorage.setItem('id', resData.id);
        localStorage.setItem('role', resData.userRole);
        localStorage.setItem(
          'name',
          resData.firstName + ' ' + resData.lastName
        );

        if (userId != null) {
          localStorage.setItem('customerId', userId);
        }

        console.log('back url: ', backUrl);
        if (backUrl) {
          this.router.navigateByUrl(backUrl);
        } else if (userId != null && payload.adminUserId) {
          this.router.navigate(['menu/sunday-delivery-menu-mEmLP']);
        } else {
          this.router.navigate(['../']);
        }
        return res;
      })
    );
    return response$;
  }
  islogin() {
    const token = this.getUserToken();

    if (this.isValidToken(token)) {
      return true;
    }

    return false;
  }
  loginOnly(payload: GENERATE_TOKEN_PAYLOAD) {
    const response$ = this.generateToken(payload).pipe(
      map((res: any) => {
        const resData = res.data;
        localStorage.setItem('jwt', resData);
        return res;
      }),
      mergeMap((res) => this.getUserDetails()),
      map((res: any) => {
        const resData = res.data;
        localStorage.setItem('id', resData.id);
        localStorage.setItem('role', resData.userRole);
        localStorage.setItem(
          'name',
          resData.firstName + ' ' + resData.lastName
        );
        return res;
      })
    );
    return response$;
  }

  signUpAndLogin(payload: SIGNUP_PAYLOAD_ALT) {
    const response$ = this.register(payload).pipe(
      concatMap((_) =>
        this.loginOnly({
          emailOrPhone: payload.emailAddress,
          password: payload.password,
          adminUserId: null,
        })
      ),
      concatMap((res) => this.getUserDetails()),
      map((res: any) => {
        const resData = res.data;
        localStorage.setItem('id', resData.id);
        return res;
      })
    );

    return response$;
  }

  saveData(payload: UPDATE_USER_PAYLOAD) {
    return this.httpService.post(UPDATE_USER_DETAILS_ENDPOINT, payload);
  }
  userAuthenticated(): boolean {
    const token = this.getUserToken();
    const expired = this.jwtHelper.isTokenExpired(token as string | undefined);
    return !expired;
  }

  isValidToken(token: string | null | undefined) {
    const expired = this.jwtHelper.isTokenExpired(token as string | undefined);
    return !expired;
  }
  
  logoutUser() {
    localStorage.clear();
  }
  isEmail(search:string):boolean
  {
      var  serchfind:boolean;

   const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      serchfind = regexp.test(search);
      console.log(serchfind);
      return serchfind;
  }
  private redirectUser() {
    const role = this.getAuthenticatedUserRole();

    if (role?.toLowerCase() === 'role_admin') {
      this.router.navigateByUrl('admin/orders');
      return;
    }
    // if (role?.toLowerCase() === 'usm_emp' || role?.toLowerCase() === 'consumer') {
    //   this.router.navigateByUrl('admin/shipments');
    //   return;
    // }
    return;
  }
}
