import { Models } from '@pnp/shared';

export interface IPickupLocationModel {
  order_instruction: string | any;
  id: number;
  location_name: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  postal_code: string;
  status: string;
  isDeleted: number;
}
export enum enumDeliveryMethods {
  'HOME_DELIVERY',
  'IN_STORE_PICKUP',
}
export interface IGratuitySelectionOption {
  field: string;
  value: number;
}
export const deliveryMethods = ['HOME DELIVERY', 'IN-STORE PICKUP'];
export interface ICheckoutState {
  orderItems: Models.Schemas.CartItemsVo[];
  addressTypes: any[];
  pickupLocations: IPickupLocationModel[];
  deliveryMethods: typeof deliveryMethods;
  selectedDeliveryMethod: enumDeliveryMethods;
  selectedDeliveryAddressId: number | null;
  selectedPickupLocationId: number | null;
  orderRoutingInstructions: string | null;
  availableDeliveryOnZipCode: boolean;
  zipCodeFeeData: Models.Schemas.ZipCodeDeliveryVo | null;
  coolerBagAmount: number;
  walletBalance: number;
  coolerBagRequired: boolean;
  coolerBagInfo: string;
  gratuityOptions: IGratuitySelectionOption[];
  currentGratuity: IGratuitySelectionOption;
  currentGratuityAmount: number;
  coupons: Models.Schemas.Coupons[];
  appliedCoupon: Models.Schemas.Coupons | null;
  discount: number;
  menuOption: any;
}

export const InitialCheckoutStates: ICheckoutState = {
  addressTypes: [],
  pickupLocations: [],
  selectedDeliveryMethod: enumDeliveryMethods.HOME_DELIVERY,
  deliveryMethods,
  selectedDeliveryAddressId: null,
  selectedPickupLocationId: null,
  availableDeliveryOnZipCode: false,
  orderRoutingInstructions: null,
  zipCodeFeeData: null,
  coolerBagAmount: 0.0,
  walletBalance: 0.0,
  coolerBagRequired: false,
  coolerBagInfo:
    'Need a cooler bag for your delivery? Check this option to add a cooler bag to your order. If a cooler bag is not chosen here at check out, a cooler bag will not be available during delivery. To receive credit for this cooler bag with your next order, return this cooler bag to our drivers.',
  gratuityOptions: [
    { field: '0%', value: 0 },
    { field: '10%', value: 10 },
    { field: '15%', value: 15 },
    { field: '20%', value: 20 },
    { field: 'Custom', value: -1 },
  ],
  currentGratuity: { field: '0%', value: 0 },
  currentGratuityAmount: 0,
  coupons: [],
  appliedCoupon: null,
  discount: 0.0,
  orderItems: [],
  menuOption: undefined,
};
