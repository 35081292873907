import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export enum enumHttpMethods {
  'GET',
  'POST',
}
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private client: HttpClient, private router: Router) {}

  get(baseUrl: string, url: string) {
    return this.client.get(baseUrl + url);
  }

  post(baseUrl: string, url: string, payload: any) {
    return this.client.post(baseUrl + url, payload);
  }
  delete(baseUrl: string, url: string) {
    return this.client.delete(baseUrl + url);
  }

  fileUploadRequest(baseUrl: string, url: string, formData: FormData) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.client.post(baseUrl + url, formData, { headers: headers });
  }
}
