import { Models } from '@pnp/shared';
import {
  ICheckoutState,
  InitialCheckoutStates,
} from '../components/checkout/store/checkout.state';
import { IUserModel } from './models/user.model';

export const deliveryMethods = ['HOME DELIVERY', 'IN-STORE PICKUP'];

export interface IRootState {
  menuOptions: any[];
  user: Partial<IUserModel>;
  cart: Partial<Models.Schemas.CartVo>;
  addressTypes: any[];
  similarItems: any[];
  checkoutState: ICheckoutState;
  customer: Partial<IUserModel>;
  stripeCustomer: any;
}

export const InitialRootState: IRootState = {
  menuOptions: [],
  addressTypes: [],
  user: {},
  cart: {},
  similarItems: [],
  checkoutState: InitialCheckoutStates,
  customer: {},
  stripeCustomer: {}
};
