import { catchError, throwError } from 'rxjs';
import { ResponseError } from './../types';
/**
 * @Author Nirjan
 ** Intercepts each request and catches network error in a graceful manner
 ** and passes the errors onwards.
 */

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((e: HttpErrorResponse) => {
        return throwError(() => {
          let error: Partial<ResponseError> = new Error(e.error.message);
          error.code = e.error.code;
          return error;
        });
      })
    );
  }
}
