import { Injectable } from '@angular/core';
import { FETCH_PUBLISHED_MENU_BY_MENU_OPTION } from '@pnp/shared';
import { EMPTY } from 'rxjs';
import {
  FETCH_DEADLINE_DATE,
  FETCH_DELIVERY_DATE,
  FETCH_ITEMS_BYID_ENDOINT,
  GET_ACTIVE_CATEGORIES_ENDPOINT,
  GET_ACTIVE_DISH_TYPES_ENDPOINT,
  GET_ACTIVE_MEAL_TYPES_ENDPOINT,
  GET_ACTIVE_MENU_OPTIONS,
  GET_MENU_DETAILS_BY_MENU_OPTION_SLUG,
  GET_MENU_ITEMS_BY_SLUG,
  SEARCH_MENU_ITEMS_ENDPOINT,
  SEARCH_MENU_ITEMS_PAYLOAD,
  UPDATE_SUBSCRIPTION,
} from 'src/app/shared/constants';
import { DateService } from 'src/app/shared/services/date.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { CHECK_DELIVERY_CLOSURE } from './../../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  updateSubscripton(payload: {
    subId: any;
    menuItems: (number | undefined)[];
  }) {
    return this.httpService.post(UPDATE_SUBSCRIPTION, payload);
  }
  getDeliveryDate(menuOption: string | null) {
    if (menuOption)
      return this.httpService.get(
        FETCH_DELIVERY_DATE.replace('<slug>', menuOption)
      );

    return EMPTY;
  }
  getItemDetailsbyId(id: any) {
    return this.httpService.get(FETCH_ITEMS_BYID_ENDOINT + id);
  }

  constructor(
    private httpService: HttpService,
    private dateService: DateService
  ) {}

  getAllActiveWeeklyMenu() {
    return this.httpService.get(GET_ACTIVE_MENU_OPTIONS);
  }
  getAllActiveCategory() {
    return this.httpService.get(GET_ACTIVE_CATEGORIES_ENDPOINT);
  }
  getAllActiveDishTypes() {
    return this.httpService.get(GET_ACTIVE_DISH_TYPES_ENDPOINT);
  }
  getAllActiveMealTypes() {
    return this.httpService.get(GET_ACTIVE_MEAL_TYPES_ENDPOINT);
  }
  searchMenuItems(payload: SEARCH_MENU_ITEMS_PAYLOAD) {
    return this.httpService.post(SEARCH_MENU_ITEMS_ENDPOINT, payload);
  }

  getMenuDetailsByMenuOption(slug: string) {
    return this.httpService.get(
      GET_MENU_DETAILS_BY_MENU_OPTION_SLUG.replace('<slug>', slug)
    );
  }

  fetchCurrentMenuDetail(menuOptionSlug: string) {
    return this.httpService.get(
      FETCH_PUBLISHED_MENU_BY_MENU_OPTION.replace(
        '<menu_option_slug>',
        menuOptionSlug
      )
    );
  }
  getMenuDetailsBySlug(menuOptionSlug: string, itemSlug: string) {
    return this.httpService.get(
      GET_MENU_ITEMS_BY_SLUG.replace(
        '<menu_option_slug>',
        menuOptionSlug
      ).replace('<item_slug>', itemSlug)
    );
  }

  getDeadlineDate(menuOptionSlug: string) {
    return this.httpService.get(
      FETCH_DEADLINE_DATE.replace('<slug>', menuOptionSlug)
    );
  }

  checkDeliveryClosure(menuOptionSlug: string) {
    return this.httpService.get(
      CHECK_DELIVERY_CLOSURE.replace('<slug>', menuOptionSlug)
    );
  }
  evaluateOrderValidity(weekDay: string, deadlineDay: string): boolean {
    let isValid = true;

    // get the delivery date
    const deliveryDate = this.dateService.getNextDayOfTheWeek(weekDay, true);

    // get the deadline date

    return isValid;
  }
}
