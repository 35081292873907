<section class="footersection">
  <div class="footertop">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <h2>Address</h2>
          <ul>
            <li><span><i class="fa fa-map-marker" aria-hidden="true"></i></span> Chicago, Illinois, USA </li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <h2>Contact</h2>
          <ul>
            <li><a href="mailto:angela@preppednplated.com"><span><i aria-hidden="true" class="fa fa-envelope"></i>
                </span> angela@preppednplated.com</a></li>
            <li><a href="tel:1-630-212-8568"><span><i aria-hidden="true" class="fa fa-phone"></i></span>
                1-630-212-8568</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <h2>Quick links</h2>
          <ul>
            <li><a routerLink="/about-us">About Us</a></li>
            <li><a routerLink="/reviews">Reviews</a></li>
            <li><a routerLink="/gallery">Gallery</a></li>
            <li><a routerLink="/faq">FAQ</a></li>
            <li><a routerLink="/partnership">Partnership</a></li>
            <li><a routerLink="/policy">Privacy Policy</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
          <h2>Follow Us</h2>
          <ul class="iconbox">
            <li><a href="https://www.facebook.com/preppednplated/" target="_blank" title="facebook"><i
                  aria-hidden="true" class="fa fa-facebook"></i></a></li>
            <li><a href="https://www.instagram.com/preppednplatedfoods/" target="_blank" title="twitter"> <i
                  class="fa fa-instagram" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footerbottom">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <p>&copy; 2023 <a href="https://preppednplated.com/" target="_blank">Prepped n' Plated</a> All Rights
            Reserved. Powered by <a href="https://www.dccil.com/#/home" target="_blank">Data Consultants Corporation</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>