export const BASE_URL_LOCAL = 'http://localhost:9090/api/';
// export const BASE_URL_DEV = "https:///3.111.47.192/api/api/"
export const BASE_URL_DEV = 'https://dev.preppednplated.com/api/api/';
export const BASE_URL_PROD = 'https://preppednplated.com/api/api/';

//* login related endpoints */
export const GENERATE_TOKEN = 'user/generateToken';
export type GENERATE_TOKEN_PAYLOAD = {
  emailOrPhone: string;
  password: string;
  adminUserId: any;
};

// Stripe Customer Api
export const GET_STRIPE_CUSTOMER_ENDPOINT =
  'stripecustomer/fetchStripeCustomer?userId=<userId>';
export const SAVE_STRIPE_CUSTOMER_ENDPOINT =
  'stripecustomer/saveStripeCustomer';

export const GET_USER_DETAILS = 'user/getUserDetails';
export const GET_USER_DETAILS_BY_ID = 'user/getuserdetailsbyid/<user_id>';
export const FATCH_USERDETAILS_BY_ID_ENDPOINT = 'user/getuserdetailsbyid/';
export const FATCH_CUSTOMER_DETAILS = 'user/getCustomerList';
export const GET_CUSTOMER_DETAILS = 'user/getuserdetailsbyid/<cus_id>';
export const SIGN_UP_ENDPOINT = 'user/signup';
export const SIGN_UP_ENDPOINT_ALT = 'user/register';
export const UPDATE_USER_DETAILS_ENDPOINT = 'user/updateuserDetails';
export type UPDATE_USER_PAYLOAD = {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNo: string;
};
export type SIGNUP_PAYLOAD = {
  emailOrPhone: string;
  password: string;
};
export type SIGNUP_PAYLOAD_ALT = {
  emailAddress: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNo: string;
};

export type ORDER_LIST_PAYLOAD = {
  page: number;
  size: number;
  orderId: string;
  userId: number | string;
  orderStatus: string;
  orderStartDate: any;
  orderEndDate: any;
};
export const fatch_ORDERS_ENDPOINT = 'orders';
export const FETCH_ORDER_BY_ID = 'orders/<order_id>';
export const GENERATE_ORDER_INVOICEPDF_ENDPOINT =
  'pdfcontroller/downloadPDF?orderId=<order_id>';
//** Product related endpoints */
//! user prefix on below endpoints must be changed after the service fixup
export const FETCH_ALL_CATEGORIES_ENDPOINT = 'products/getCategoryList';
export const DELETE_CATEGORY_ENDPOINT = 'user/deleteCategory?catId=<cat_id>';
export const CHANGE_CATEGORY_ENDPOINT =
  'user/changeCategoryStatus?catId=<cat_id>&status=<status_code>';
export type ADD_CATEGORY_PAYLOAD = {
  categoryName: string;
  status: string;
  isDeleted: number;
};
export const ADD_CATEGORY_ENDPOINT = 'user/addCategory';
export const ADD_DISH_ENDPOINT = 'user/addDish';
export const FEATCH_ALL_DISHTYPE_ENDPOINT = 'user/getDishList';
export const DELETE_DISH_ENDPOINT = 'user/deleteDish?dishId=<dish_id>';
export const CHANGE_DISH_STATUS_ENDPOINT =
  'user/changeDishStatus?dishId=<dish_id>&status=<status_code>';

export type ADD_DISH_PAYLOAD = {
  dishTypeName: string;
  status: string;
  isDeleted: number;
};

export const ADD_MEAL_ENDPOINT = 'user/addMeal';
export const FATCH_ALLMEAL_ENDPOINT = 'user/getMealList';
export const DELETE_MEAL_ENDPOINT = 'user/deleteMeal?mealId=<meal_id>';
export const CHANGE_MEAL_STATUS_ENDPOINT =
  'user/changeMealStatus?mealId=<meal_id>&status=<status_code>';
export type ADD_MEALPAYLOAD = {
  mealTypeName: string;
  status: string;
  isDeleted: number;
};

export const ADD_WEEKLYMENU_ENDPOINT = 'user/addweeklymenu';
export const FETCH_ALL_WEEKLY_MENU_ENDOINT = 'user/getWeeklyMenulist';
export const FETCH_ALL_ACTIVE_WEEKLY_MENU_ENDOINT =
  'user/getActiveWeeklyMenulist';
export const DELETE_WEEKLY_MENU_ENDPOINT =
  'user/deleteweeklyMenu?menuId=<menu_Id>';
export const CHANGE_WEEKLY_MENU_STATUS_ENDPOINT =
  'user/changeWeeklyMenuStatus?menuId=<menu_id>&status=<status_code>';
export type ADD_WEEKLY_MENU_PAYLOAD = {
  weeklyMenu: String;
  isDeleted: number;
  status: string;
  weekDay: string;
};

export type SEARCH_ITEMS_PAYLOAD = {
  itemName?: string;
  categoryName?: string;
  mealTypeName?: string;
  dishTypeName?: string;
  page?: number;
  size?: number;
};
export const SEARCH_ITEMS_ENDPOINT = 'products/items';

export type ADD_ITEM_PAYLOAD = {
  itemId?: string;
  itemName: string;
  new: boolean;
  category: any;
  mealType: any;
  dishType: any;
  price: string;
  orderInstructions: string;
  reheatInstructions: string;
  carbs: string;
  fat: string;
  calories: string;
  protein: string;
};
export const ADD_ITEM_ENDPOINT = 'products/items/add';
export const DELETE_ITEM_ENDPOINT = 'products/items/delete?itemId=<item_id>';

export const GET_ITEM_ENDPOINT = 'products/items/<item_id>';
export const CHANGE_ITEM_STATUS_ENDPOINT =
  'products/items/changeStatus?itemId=<item_id>&status=<status_code>';
export const FETCH_ITEMS_BYID_ENDOINT = 'user/items/';
export type FORM_DATA_UPLOAD_ITEM_FILE = {
  file: File;
  fileId?: string;
  formCode: string;
  mediaType: string;
};
export const UPLOAD_ITEM_FILE = 'fe/uploadSingleFileInFolder';

export const FATCH_ADDRESS_TYPE = 'address/getalladdresstype';
export const ADD_ADDRESS_ENDPOINT = 'address/addaddress';
export type ADD_ADDRESS_PAYLOAD = {
  User_id: any;
  ref_id: any;
  city: String;
  state: String;
  postal_code: string;
  default_address: String;
  street1: String;
  street2: string;
};
export const EDIT_ADDRESS_ENDPOINT = 'address/editaddress';
export type EDIT_ADDRESS_PAYLOAD = {
  id: string | number;
  Userid: any;
  ref_id: any;
  city: String;
  state: String;
  postal_code: string;
  street1: String;
  street2: string;
};
export const DELETE_ADDRESS_ENDPOINT = 'address/deleteaddress/';
export const DELETE_ADDRESS_ENDPOINT_ALT =
  'customer/deleteAddressById?addressId=<address_id>';
export const SET_DEFAULT_ADDRESS = 'address/setdefaultaddress';
export type SET_DEFAULT_ADDRESS_PAYLOAD = {
  id: string | number;
  userId: number | string;
};
export const FATCH_ADDRESS_BY_ID = 'address/fatchaddressByid/';

export const FORGET_PASSWORD_ENDPOINT = 'user/sendForgetPasswordMailLink';
export const FATCH_EMAIL_BY_TOKEN_ENDPOINT = 'user/findEmailByToken/';
export const UPDATE_PASSWORD_BY_ID = 'user/updatepasswordbyid';
export type RESET_PASSWORD_PAYLOAD = {
  id: number;
  password: string;
  newpassword: string;
};
export type UPDATE_PASSWORD_PAYLOAD = {
  id: number | string;
  email: string;
  password: string;
};
export type FORGOT_PASSWORD_PAYLOAD = {
  email: string;
};
export const RESET_PASSWORD_ENDPOINT = 'user/resetpasswordbyId';

export const GET_ALL_ACTIVE_MENU_DETAILS_ENDPOINT =
  'products/activeMenuDetails';
export type CREATE_MENU_ITEM_PAYLOAD = {
  menuDetails: any;
  items: any[];
};
export const CREATE_MENU_ITEM_ENDPOINT = 'products/menuitem';
export const GET_CHOSEN_ITEMS = 'products/chosenItems?id=<menu_detail_id>';
export const GET_REMAINING_ITEMS =
  'products/remainingItems?id=<menu_detail_id>';

/********** User Side  Endpoints *****************************************/
export const GET_ACTIVE_NEW_ITEMS_FE_ENDPOINT =
  'fe/allNewItems?page=<page>&size=<size>';
export const GET_MENU_OPTIONS_FE_ENDPOINT = 'fe/allMenuOptions';

export type SEARCH_MENU_ITEMS_PAYLOAD = {
  itemName?: string;
  categories?: [string];
  mealTypes?: any[];
  dishTypes?: [string];
  menuOptionSlug?: string;
  maxPrice?: string;
  page?: number;
  size?: number;
  menuOptionId?: string;
};
export const SEARCH_MENU_ITEMS_ENDPOINT = 'menuItems';
export const GET_MENU_DETAILS_BY_MENU_OPTION_SLUG =
  'menu/details/getPublishedMenu?menuOption=<slug>';

export const GET_ACTIVE_MENU_OPTIONS = 'user/getActiveWeeklyMenulist';
export const GET_ACTIVE_CATEGORIES_ENDPOINT = 'products/getActiveCategoryList';
export const GET_ACTIVE_DISH_TYPES_ENDPOINT = 'user/getActiveDishTypeList';
export const GET_ACTIVE_MEAL_TYPES_ENDPOINT = 'user/getActiveMealList';

// ********************** Catt Items

export type CART_ITEMS_PAYLOAD = {
  id?: number;
  cartItems?: [];
  menuOption?: {};
  deliveryDate?: string;
  deliveryFee?: string;
  coolerBagAmount?: string;
  coolerBagRequired?: boolean;
  userVo?: {};
  isDeleted?: string;
  instructions?: string;
};
export const CART_ITEMS_ENDPOINT = 'cart/addToCart';
export const GET_CART_ITEMS_ENDPOINT = 'cart/getItemsByUser?userId=<user_id>';
export const GET_CART_ITEMS_ENDPOINT_ALT = 'cart/getItems';
export const DELETE_CART_ITEMS_ENDPOINT =
  'cart/deleteCartItem?itemId=<item_id>';
export const MODIFY_CART_ITEM_QTY = 'cart/modifyItemQty';
export const REMOVE_CART_ITEM = 'cart/deleteCartItem';

// ************************** Cart Items

export const GET_MENU_ITEMS_BY_SLUG =
  'menuItems/details?menuOption=<menu_option_slug>&item=<item_slug>';

//******************** Checkout related ******************//
export type PAYLOAD_UPDATE_USER_INFO = {
  firstName?: string;
  lastName?: string;
  phoneNo?: string;
  emailAddress?: string;
};
export const UPDATE_USER_INFO_ENDPOINT = 'customer/updateBasicInfo';
export const GET_ADDRESS_TYPES = 'customer/addressTypes';
export type PAYLOAD_UPDATE_USER_ADDRESS = {
  id?: string;
  ref_id: {
    id: string;
    name: string;
  } | null;
  User_id?: {
    id: string;
  };
  street1: string;
  street2?: string;
  city: string;
  postal_code: string;
  state: string;
  order_instruction: string;
};
export const SAVE_CUSTOMER_ADDRESS = 'customer/updateAddress';
export type PAYLOAD_MAKE_DEFAULT_ADDRESS = {
  id: string;
  User_id: {
    id: string | null;
  };
};
export const MAKE_DEFAULT_ADDRESS = 'customer/makeAddressDefault';
export const FETCH_ALL_PICK_LOCATIONS = 'picklocation';
//* orders *//
export type PAYLOAD_PLACE_ORDER = {
  refOrderModeType: {
    seq: string | number | null;
  };
  routingInstructions: string | null;
  menuOption: {
    id: string | number | null;
  };

  shippingAddress: {
    id: string | number | null;
  };
  pickupLocation: {
    id: string | number | null;
  };
  coolerBagRequired?: boolean;
  discount: number;
  couponId: number;
  txnId: string;
  paymentMode: string;
  gratuityPercent: string;
  gratuityAmount: string;
  customerId: string;
  gatewayType?: number;
};
export const PLACE_ORDER_ENDPOINT = 'orders/place';

export const INIT_PLACE_ORDER = 'orders/initiateOrder';
export const CHECK_POSSIBLE_DELIVERY_ENDPOINT =
  'zipcode/checkPossibleDelivery?zipCode=<zip_code>&menuOptionId=<menu_option_id>';

export const CREATE_PAYMENT_LINK = 'payments/createPaymentLink';
export type PAYLOAD_CREATE_PAYMENT_LINK = {
  menuOptionId: string;
  deliveryZipCode: string;
  orderId: string;
};
//************** APP CONSTANTS */
export enum enumLocalStorageKey {
  'userKey' = 'id',
  'cartKey' = 'localCart',
  'token' = 'jwt',
}

export const CHECK_WALLET_ENDPOINT = 'orders/checkWallet?userId=<user_id>';

export const FETCH_SIMILAR_ITEMS = 'fe/getSimilarItems';
export const FETCH_SIMILAR_IEMS_BY_MENU_OPTION =
  'fe/getSimilarItemsByMenuOption?menuOption=<slug>';
export const FETCH_DEADLINE_DATE = 'fe/getDeadlineDate?menuOption=<slug>';
export const CHECK_DELIVERY_CLOSURE =
  'fe/checkDeliveryClosure?menuOption=<slug>';
export const FETCH_DELIVERY_DATE = 'fe/deliveryDate?menuOption=<slug>';
export const FETCH_ALL_ORDER_STATUS = 'orders/status';

export type PAYLOAD_ADD_GRATUITY = {
  gratuityAmount: string;
  gratuityPercentage: string | null;
};
export const ADD_GRATUITY = 'cart/addGratuity';

export const FETCH_COUPONS = 'coupon/fetchCouponsUser';

export const FETCH_COUPON_USES_LEFT =
  'coupon/checkNoOfUsesCoupon?userId=<user_id>&couponId=<coupon_id>';
export const CHECK_DELIVERY_ZIPCODE =
  'zipcode/checkPossibleDeliveryZipcode?zipCode=<zip_code>';

export const CREATE_SUBSCRIPTION_SESSION = 'subscription';

export const FETCH_SUBSCRIPTION_BY_ID =
  'subscription/fetchSubscriptionById?subId=<sub_id>';
export const FETCH_SUBSCRIPTION_LIST = 'subscription/getallsubscription';
export const UPDATE_SUBSCRIPTION = 'subscription/update';
export const PAUSE_SUBSCRIPTION = 'subscription/pause?subId=<sub_id>';
export const RESUME_SUBSCRIPTION = 'subscription/resume?subId=<sub_id>';
export const CANCEL_SUBSCRIPTION = 'subscription/cancel?subId=<sub_id>';
export const FETCH_SUB_CATEGORIES = 'subscription/categories';
export const FETCH_SUB_MEAL_TYPES_BY_SUBCATEGORY =
  'subscription/categories/<subcategory_id>/mealTypes';
