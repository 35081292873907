export type ResponseType = {
  data: any;
  loading: boolean;
  success: boolean;
  error?: boolean;
  errorData?: any;
};

export type ResponseTypeDup = {
  data: any;
  code: any;
  message: any;
  loading: boolean;
  success: boolean;
  error?: boolean;
};

export type SharedState = {
  user: ResponseType;
  cartItems: ResponseType;
  cartItemsCount: number | null;
};

export const InitialSharedState: SharedState = {
  user: {
    data: null,
    loading: false,
    success: false,
  },

  cartItems: {
    data: [],
    loading: false,
    success: false,
  },
  cartItemsCount: 0,
};
