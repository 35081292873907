import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, Utils } from '@prepped-n-plated/shared';
import { PAYLOAD_UPDATE_USER_ADDRESS } from './../../constants';

@Component({
  selector: 'root-app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootAddressDialogComponent implements OnInit, OnChanges {
  @Input('addressTypes') addressTypes!: any[];
  @Input('updateAddressCallback') updateAddressCallback!: () => void;
  @Input('loadingState') loadingState!: boolean;
  @Input('visibility') addressDialogVisible!: boolean;
  @Input('inputAddress') inputAddress?: PAYLOAD_UPDATE_USER_ADDRESS;
  @Output('outputAddress')
  outputAddress!: EventEmitter<PAYLOAD_UPDATE_USER_ADDRESS>;
  @Output('toggleVisibility') toggleVisibility!: EventEmitter<boolean>;

  addressForm!: FormGroup;
  constructor(private formBuilder: FormBuilder, private utils: Utils,private authService:AuthService) {
    this.toggleVisibility = new EventEmitter<boolean>();
    this.outputAddress = new EventEmitter<PAYLOAD_UPDATE_USER_ADDRESS>();
  }

  ngOnInit(): void {
    this.initAddressForm();
    this.addressForm.valueChanges.subscribe((data) => console.log(data));
  }
  initAddressForm() {
    this.addressForm = this.formBuilder.group({
      id: [''],
      ref_id: [{}, this.utils.validateProperty('id', [Validators.required])],
      street1: ['', Validators.required],
      street2: [''],
      city: ['', Validators.required],
      postal_code: ['', Validators.required],
      state: ['', Validators.required],
      country: ['US'],
      order_instruction: ['', Validators.required],
      User_id:[{id:this.authService.getAuthenticatedUserId()}]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputAddress) this.patchAddressForm();
    if (this.addressTypes)
      this.addressForm.patchValue({ ref_id: this.addressTypes[0] });
  }
  patchAddressForm() {
    this.addressForm.patchValue({
      id: this.inputAddress?.id,
      ref_id: this.inputAddress?.ref_id,
      street1: this.inputAddress?.street1,
      street2: this.inputAddress?.street2,
      city: this.inputAddress?.city,
      state: this.inputAddress?.state,
      postal_code: this.inputAddress?.postal_code,
      order_instruction: this.inputAddress?.order_instruction,
      country: 'US',
    });
  }
  setDialogInvisible() {
    this.toggleVisibility.emit(false);
  }

  sendOutAddress() {
    let formValue = this.addressForm.value;
    delete formValue.country;
    this.outputAddress.emit(formValue);
  }
}
