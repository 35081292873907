<p-dialog [visible]="dialogVisible" [breakpoints]="{ '960px': '55vw', '640px': '75vw' }" [style]="{ width: '50vw' }"
    position="center" [modal]="true" closeIcon="none">
    <ng-template pTemplate="header">
        <h4>Update Basic Information</h4>
    </ng-template>
    <div class="grid" [formGroup]="infoForm">
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">First Name</label>
            <input type="text" pInputText placeholder="Enter first name" class="w-full" formControlName="firstName" />

        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">Last Name</label>
            <input type="text" pInputText placeholder="Enter last name" class="w-full" formControlName="lastName" />
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">Email Address </label>
            <input type="text" pInputText class="w-full" formControlName="emailAddress" readonly />
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">Phone No.</label>
            <p-inputMask mask="(999) 999-9999" [unmask]="true" placeholder="Enter phone no"
                formControlName="phoneNo"></p-inputMask>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <button pButton label="Cancel" class="p-button-outlined mx-3" (click)="setDialogInvisible()"></button>
        <button pButton label="Save" (click)="sendOutInfo()" icon="pi pi-save"></button>
    </ng-template>
</p-dialog>