import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Utils } from '@prepped-n-plated/shared';

export interface IGratuitySelectionOption {
  field: string;
  value: number;
}
@Component({
  selector: 'app-gratuity-section',
  templateUrl: './gratuity-section.component.html',
  styleUrls: ['./gratuity-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GratuitySectionComponent implements OnChanges {
  @Input('options') options!: IGratuitySelectionOption[];
  @Input('defaultOption') defaultOption!: IGratuitySelectionOption;
  @Input('defaultCustomAmount') defaultCustomAmount!: number;
  @Input('inputAmount') inputAmount!: number;
  @Output('output') output!: EventEmitter<any>;

  gratuityStatus!: IGratuitySelectionOption;
  customGratuity!: number;

  constructor(private utils: Utils) {
    this.gratuityStatus = { field: '0%', value: 0 };
    this.customGratuity = 0;
    this.defaultCustomAmount = 0;
    this.output = new EventEmitter<any>();
    this.output.emit({
      amount: this.utils.percentage(
        this.gratuityStatus.value,
        this.inputAmount
      ),
      gratuity: this.gratuityStatus,
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.gratuityStatus = this.defaultOption;
    this.customGratuity = this.defaultCustomAmount;
  }

  calculateOutput() {
    if (this.gratuityStatus.value !== -1)
      this.output.emit({
        amount: this.utils.percentage(
          this.gratuityStatus.value,
          this.inputAmount
        ),
        gratuity: this.gratuityStatus,
      });
  }

  sendCustomGratuityAmount() {
    this.output.emit({
      gratuity: this.gratuityStatus,
      amount: this.customGratuity,
    });
  }
}
