import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'precise',
})
export class PrecisePipe implements PipeTransform {
  transform(value: string | number, precision: number): string | null {
    if (!value) return null;

    if (typeof value === 'string') value = Number(value);
    return Number(value).toFixed(precision);
  }
}
