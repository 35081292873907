import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: string, type: 'US'): string {
    if (!value) return '';
    const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (!x) return '';
    const res = '(' + x[1] + ') ' + x[2] + '-' + x[3];
    return res;
  }
}
