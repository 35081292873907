import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItem } from '@prepped-n-plated/shared';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuCardComponent {
  @Input('data') data!: MenuItem;
  @Input('variant') variant?: 'minimal' | 'extended';
  @Input('quickViewCallback') quickViewCallback!: (item: MenuItem) => void;
  @Input('addToCartCallback') addToCartCallback!: (
    item: MenuItem,
    deleteExistingCart: boolean
  ) => void;

  constructor() {
    if (!this.variant) this.variant = 'extended';
  }
}
