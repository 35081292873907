import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AESEncryptDecryptService } from './AESEncryptDecryptService.service';
import { ApiComponent } from './api.component';

@NgModule({
  declarations: [ApiComponent],
  imports: [HttpClientModule],
  exports: [ApiComponent],
  providers: [AESEncryptDecryptService]
})
export class ApiModule {}
