import { Models } from '@pnp/shared';
import { ResponseType } from 'src/app/shared/store/shared.state';

export type MenuState = {
  menuItems: ResponseType;
  menuDetails: ResponseType;
  activeMenuOptions: ResponseType;
  activeCategories: ResponseType;
  activeDishTypes: ResponseType;
  activeMealTypes: ResponseType;
  itemDetails: ResponseType;
};

export const InitialMenuState: MenuState = {
  menuDetails: {
    data: null,
    loading: false,
    success: false,
  },
  itemDetails: {
    data: null,
    loading: false,
    success: false,
  },
  menuItems: {
    data: [],
    loading: false,
    success: false,
  },
  activeMenuOptions: {
    data: [],
    loading: false,
    success: false,
  },
  activeCategories: {
    data: [],
    loading: false,
    success: false,
  },
  activeDishTypes: {
    data: [],
    loading: false,
    success: false,
  },
  activeMealTypes: {
    data: [],
    loading: false,
    success: false,
  },
};

export interface IMenuState {
  activeMenuOption: string | number | null;
  deadlineDate: { menuOption: string; deadlineDate: string }[];
  deliveryDate: string | null;
  isDeliveryClosed: boolean;
  deliveryClosureMsg: string;
  currentMenuDetail: Models.Schemas.MenuDetailsVo | null;
  doesDifferentCartExist: boolean;
}

export const InitialMenuStateAlt: IMenuState = {
  activeMenuOption: null,
  deliveryDate: null,
  deadlineDate: [],
  isDeliveryClosed: false,
  deliveryClosureMsg: 'Delivery closed for <delivery_menu>.',
  currentMenuDetail: null,
  doesDifferentCartExist: false,
};
