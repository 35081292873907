import { createReducer, on } from '@ngrx/store';
import { RootActions } from './root.action';
import { InitialRootState } from './root.state';

export const RootReducers = createReducer(
  InitialRootState,
  on(RootActions.userFetchSuccessful, (state, action) => ({
    ...state,
    user: action.user,
  })),

  on(RootActions.fetchUserByIdSuccessful, (state, action) => ({
    ...state,
    customer: action.user,
  })),

  on(RootActions.cartFetchSuccessful, (state, action) => ({
    ...state,
    cart: action.cart,
  })),
  on(RootActions.menuOptionsFetchSuccessful, (state, action) => ({
    ...state,
    menuOptions: action.menuOptions,
  })),

  on(RootActions.fetchDeliveryAddressTypesSuccess, (state, action) => ({
    ...state,
    addressTypes: action.addressTypes,
  })),

  on(RootActions.fetchSimilarItemsSuccess, (state, action) => ({
    ...state,
    similarItems: action.similarItems,
  })),
  on(RootActions.userLoggedOut, (state, _) => ({
    ...state,
    user: {},
    cart: {},
  })),
  on(RootActions.fetchStripeCustomerSuccessful, (state, action) => ({
    ...state,
    stripeCustomer: action.stripeCustomer,
  }))
);
