import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Models } from '@pnp/shared';
import { MessageService } from 'primeng/api';
import { concatMap, tap, throwError } from 'rxjs';
import { CartItemService } from '../../../user/cart/service/cartitem.service';
import { AlreadyExistsError } from '../../errors/AlreadyExistsError';
import { SharedStore } from '../../store/shared-store.service';
import { ResponseType } from '../../store/shared.state';
import {
  ICart,
  ICartItem,
  IMenuOption,
  ResponseError,
  enumIsDeleted,
} from '../../types';
import { AuthService } from '../auth.service';
import { DateService } from '../date.service';
import { LocalStorageService } from '../localStorageService.service';
import { ICartModel } from './../../store/models/cart.model';
@Injectable({ providedIn: 'root' })
export class GlobalCartService {
  cartKey!: string;
  userKey!: string;
  invalidItemSelectionMsg!: string;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private authService: AuthService,
    private sharedStore: SharedStore,
    private cartService: CartItemService,
    private dateService: DateService,
    private messageService: MessageService
  ) {
    this.cartKey = 'localCart';
    this.userKey = 'id';
    this.invalidItemSelectionMsg =
      'Different Delivery Menu item already in cart.';
  }

  /**
   * Adds item to cart
   * If the user is not logged in Cart data will be saved in localStorage
   * If user is logged in, localStorage data will be saved to cart.
   * @param cart
   * @param cartItem
   */
  addToCart(
    cart: ICart,
    cartItem: any,
    successMsg?: { msgKey: string; msg: string; summary: string }
  ) {
    console.log(cart);

    this.messageService.clear();
    cart.userVo.id =
      this.localStorageService.get('customerId') !== null
        ? this.localStorageService.get('customerId')
        : this.localStorageService.get('id');
    // user not logged in
    if (!this.authService.userAuthenticated()) {
      this.saveToLocalStorage(<ICart>cart, cartItem, successMsg);
      return;
    }
    // return;
    this.saveCartDataRemote(cart, cartItem, successMsg);
  }

  takeMeToCart() {
    this.router.navigate(['/cart']);
  }
  saveCart(cart: ICart) {
    console.log('Cart to be saved: ', cart);
    if (this.localStorageService.get('customerId'))
      cart.userVo.id = this.localStorageService.get('customerId');
    else cart.userVo.id = this.localStorageService.get('id');
    this.sharedStore.saveAndFetchCartItems(cart);
    return;
  }
  saveCartAfterLogin(cart: ICart) {
    console.log('Cart to be saved: ', cart);
    if (this.localStorageService.get('customerId'))
      cart.userVo.id = this.localStorageService.get('customerId');
    else cart.userVo.id = this.localStorageService.get('id');
    this.sharedStore.saveAndFetchCartItemsAfterLogin(cart);
    return;
  }
  removeCartItem(cart: ICart, cartItem: ICartItem) {
    if (!this.authService.userAuthenticated()) {
      let localCart = <ICart | null>this.localStorageService.get(this.cartKey);
      if (!localCart) return;

      let cartItems = localCart.cartItems;
      let newCartItems = <ICartItem[]>[];
      cartItems.map((item) => {
        if (item.mainItemId !== cartItem.mainItemId) newCartItems.push(item);
      });

      localCart.cartItems = newCartItems;
      this.localStorageService.set(this.cartKey, localCart);
      this.sharedStore.updateCartItems({
        data: localCart,
        loading: false,
        success: false,
      });
      this.sharedStore.updateCartItemsCount(
        this.countCartItems(localCart.cartItems)
      );
      if (localCart.cartItems.length === 0)
        this.localStorageService.remove(this.cartKey);
      return;
    }
    this.sharedStore.removeAndFetchCart(<string>cartItem.id);
  }
  removeLocalCart() {
    this.localStorageService.remove(this.cartKey);
  }

  saveCartDataRemote(
    cart: ICart,
    cartItem: ICartItem,
    successMsg?: { msgKey: string; msg: string }
  ) {
    // cart.userVo.id = this.localStorageService.get(this.userKey);
    cart.cartItems = this.updateCartItemsArray(cart.cartItems, cartItem);
    console.log('Final cartitem: ', cart);
    // return;
    this.sharedStore.saveAndFetchCartItems({ ...cart, ...successMsg });
  }

  fetchCart() {
    if (!this.authService.userAuthenticated()) {
      const localCartData = this.localStorageService.get(this.cartKey);
      const response: ResponseType = {
        data: localCartData,
        loading: false,
        success: true,
      };
      this.sharedStore.updateCartItems(response);
      this.sharedStore.updateCartItemsCount(
        this.countCartItems(response.data.cartItems)
      );
      return;
    }

    if (this.localStorageService.get(this.cartKey)) this.removeLocalCart();
    if (this.localStorageService.get('customerId') != null) {
      this.sharedStore.fetchCartItems(
        this.localStorageService.get('customerId')
      );
    } else {
      this.sharedStore.fetchCartItems(
        this.localStorageService.get(this.userKey)
      );
    }
  }

  fetchCartLocal(): ICartModel {
    const localCartData = this.localStorageService.get(this.cartKey);
    return localCartData;
  }

  saveAndFetchCartRemote(cart: ICart) {
    const response$ = this.cartService.saveCart(cart).pipe(
      concatMap(() =>
        this.cartService.fetchCatItemsApi(
          this.localStorageService.get(this.userKey)
        )
      ),
      tap({
        error: (err: ResponseError) => {
          if (err.code === 406)
            throwError(() => new AlreadyExistsError(err.message));
          throwError(() => new Error(err.message));
        },
      })
    );
    return response$;
  }
  callInvalidMenuDeadline(menuOption: Partial<IMenuOption>) {
    if (!menuOption) return '';

    const currentDate = new Date();
    const deliveryDate = this.dateService.getNextDayOfTheWeek(
      <string>menuOption.weekDay,
      true
    );

    const deadlineDate = this.dateService.getPreviousDayOfTheWeek(
      <string>menuOption.deadline_day?.toUpperCase(),
      <Date>deliveryDate
    );
    deadlineDate?.setHours(23, 59, 59, 59);

    if (currentDate.getTime() > <number>deadlineDate?.getTime()) {
      return `Order closed for ${menuOption.weeklyMenuName}`;
    }

    return '';
  }

  private saveToLocalStorage(
    cart: ICart,
    cartItem: ICartItem,
    successMsg: any
  ) {
    if (this.checkIfOldCartExists(cart))
      this.localStorageService.remove(this.cartKey);
    if (this.checkIfDifferentLocalCartExists(cart))
      throw new AlreadyExistsError(this.invalidItemSelectionMsg);

    // check if a cart already exists otherwise create a localCart object and set cart items
    const localCart = this.localStorageService.get(this.cartKey);
    if (!localCart) {
      this.buildLocalCart(cart);
    }

    this.addItemsToLocalCart(cartItem);
    this.updateSharedStoreCart(this.localStorageService.get(this.cartKey));
    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth',
    // });
    this.messageService.add({
      key: successMsg.msgKey,
      severity: 'success',
      detail: successMsg.msg,
      life: 5000,
    });
  }
  checkIfOldCartExists(cart: any) {
    if (!this.localStorageService.get(this.cartKey)) return false;

    const localCart = <any>this.localStorageService.get(this.cartKey);

    if (localCart.menuDetails.id === cart.menuDetails.id) return false;

    return true;
  }

  private checkIfDifferentLocalCartExists(cart: ICart): boolean {
    if (!this.localStorageService.get(this.cartKey)) return false;

    const localCart = <ICart>this.localStorageService.get(this.cartKey);

    if (localCart.menuOption.id === cart.menuOption.id) return false;

    return true;
  }

  private buildLocalCart(cart: ICart) {
    console.log('LocalCart: ', cart);

    cart = <ICart>{
      ...cart,
      userVo: { id: this.localStorageService.get('id') },
      deliveryFee: 0.0,
      coolerBagRequired: false,
      coolerBagAmount: 0.0,
      isDeleted: enumIsDeleted.N,
      cartItems: <ICartItem[]>[],
    };
    this.localStorageService.set(this.cartKey, cart);
  }

  private addItemsToLocalCart(cartItem: ICartItem) {
    console.log('localCart: ', this.localStorageService.get(this.cartKey));
    let localCart = <ICart>(
      (this.localStorageService.get(this.cartKey)
        ? this.localStorageService.get(this.cartKey)
        : null)
    );
    localCart.cartItems = this.updateCartItemsArray(
      localCart.cartItems,
      cartItem
    );
    this.localStorageService.set(this.cartKey, localCart);
  }

  private countCartItems(cartItems: ICartItem[]): number | null {
    let count: number = 0;
    cartItems.map((item) => {
      count += +item.itemQty;
    });
    return count === 0 ? null : count;
  }
  private updateSharedStoreCart(cart: ICart) {
    this.sharedStore.updateCartItems({
      data: cart,
      loading: false,
      success: false,
    });
    this.sharedStore.updateCartItemsCount(
      this.countCartItems(cart.cartItems) || 0
    );
  }

  private updateCartItemsArray(
    cartItems: ICartItem[],
    cartItem: ICartItem,
    incDecFlag: boolean = false
  ): ICartItem[] {
    // let itemsArr = <ICartItem[]>[];
    const ifExists = cartItems.find(
      (item) => item.mainItemId === cartItem.mainItemId
    );
    if (ifExists && !incDecFlag) {
      let newArr = cartItems.map((item) => {
        if (item.mainItemId === cartItem.mainItemId) {
          item.itemQty = <number>item.itemQty + <number>cartItem.itemQty;
        }

        return item;
      });
      cartItems = <ICartItem[]>newArr;
    } else {
      cartItems.push(cartItem);
    }

    return cartItems;
  }

  convertToCartItem(item: any): Models.Schemas.CartItemsVo {
    let cartItem: Models.Schemas.CartItemsVo = {
      mainItemId: item.itemId,
      itemName: item.itemName,
      itemPrice: item.price,
      itemSlug: item.slug,
      itemQty: item.qty || 1,
      categoryName: item.categoryName,
      mealTypeName: item.mealTypeName,
      dishTypeName: item.dishTypeName,
      itemPic: item.fileUrl,
    };
    return cartItem;
  }

  similarItemToCartItem(item: any) {
    let cartItem: Models.Schemas.CartItemsVo = {
      mainItemId: item.id,
      itemName: item.itemName,
      itemPrice: item.price,
      itemSlug: item.itemSlug,
      itemQty: item.qty || 1,
      categoryName: item.categoryName,
      mealTypeName: item.mealTypeName,
      dishTypeName: item.dishTypeName,
      itemPic: item.fileUrl,
    };
    return cartItem;
  }
  //* saving cart
  /***
   ** login action will take all the cart items and add it to db upon checkout.
   */
}
