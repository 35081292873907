import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { AuthGuard } from 'src/app/shared/guards/auth-guard.guard';
import { SharedStore } from 'src/app/shared/store/shared-store.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserLayoutModule } from './layout/user-layout/user-layout.module';
import { ErrorInterceptor } from './shared/interceptors/ErrorInterceptor';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { AuthService } from './shared/services/auth.service';
import { HttpService } from './shared/services/http.service';
import { LocalStorageService } from './shared/services/localStorageService.service';
import { SharedModule } from './shared/shared.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    UserLayoutModule,
    HttpClientModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 100 }),
    ToastModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },

    HttpService,
    AuthService,
    MessageService,
    ConfirmationService,
    DialogService,
    SharedStore,
    LocalStorageService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },

    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
