import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { catchError, Observable, throwError } from 'rxjs';
import { BASE_URL_DEV, BASE_URL_PROD } from 'src/app/shared/constants';
import { AuthService } from '../services/auth.service';
import { BASE_URL_LOCAL } from './../constants';

/**
 * @Author Nirjan
 ** Intercepts each request and appends JWT token to the request if it is a valid request.
 ** Otherwise, it logs out the user and redirects to the login page.
 */

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = req.url;
    const route = this.router.url;
    if (
      url.includes(BASE_URL_DEV) ||
      url.includes(BASE_URL_LOCAL) ||
      url.includes(BASE_URL_PROD)
    ) {
      const token = this.authService.getUserToken();
      if (this.authService.userAuthenticated()) {
        const tokenizedRequest = this.appendTokenToRequest(req, token);
        return next.handle(tokenizedRequest);
      } else {
        return next.handle(req).pipe(
          catchError((err) => {
            console.log('Catched by Interceptor: ', err.code, route);
            if (
              (err.code === 401 || err.code === '401') &&
              !route.includes('auth/login') &&
              !route.includes('cart')
            ) {
              this.authService.logoutUser();
              this.router.navigateByUrl('auth/login');
              setTimeout(() => {
                this.messageService.clear();
                this.messageService.add({
                  key: 'login-err-msgs',
                  severity: 'error',
                  summary: 'UNAUTHORIZED',
                  detail: 'Session Expired.',
                  life: 5000,
                  styleClass: 'max-z-index',
                });
              }, 750);
            }
            return throwError(() => err);
          })
        );
      }
    }

    return next.handle(req);
  }

  private validateAuthenticityAndRoute(event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      if (event.status === 401) {
        this.authService.logoutUser();
        this.router.navigateByUrl('auth/login');
      }
    }
  }

  private appendTokenToRequest(
    req: HttpRequest<any>,
    token: string | undefined | null
  ) {
    const tokenizedRequest = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });

    return tokenizedRequest;
  }
}
