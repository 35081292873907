import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number',
})
export class NumberPipe implements PipeTransform {
  transform(value: string | number | null) {
    if (!value) return 0;
    if (typeof value === 'string') value = Number(value);
    return value;
  }
}
