import { Injectable } from '@angular/core';
import {
  previousFriday,
  previousMonday,
  previousSaturday,
  previousSunday,
  previousThursday,
  previousTuesday,
  previousWednesday,
} from 'date-fns';

export enum enumDayNames {
  'SUN' = 'sun',
  'MON' = 'mon',
  'TUE' = 'tue',
  'WED' = 'wed',
  'THU' = 'thu',
  'FRI' = 'fri',
  'SAT' = 'sat',
}
@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  getNextDayOfTheWeek(
    dayName: string,
    excludeToday = true,
    refDate = new Date()
  ): Date | null {
    if (!dayName) return null;

    const dayOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(
      dayName.slice(0, 3).toLowerCase()
    );
    if (dayOfWeek < 0) return null;
    refDate.setHours(0, 0, 0, 0);
    refDate.setDate(
      refDate.getDate() +
        +!!excludeToday +
        ((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
    );
    return refDate;
  }

  getPreviousDayOfTheWeek(dayName: string, refDate: Date): Date | null {
    console.log('Day Name: ', dayName);

    if (!dayName) return null;
    const dayOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(
      dayName.slice(0, 3).toLowerCase()
    );
    if (dayOfWeek < 0) return null;

    switch (dayOfWeek) {
      case 0:
        return previousSunday(refDate);
      case 1:
        return previousMonday(refDate);

      case 2:
        return previousTuesday(refDate);
      case 3:
        return previousWednesday(refDate);
      case 4:
        return previousThursday(refDate);
      case 5:
        return previousFriday(refDate);
      case 6:
        return previousSaturday(refDate);
    }

    return null;
  }
}
// sunday delivery menu. --->
//
