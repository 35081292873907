export const BASE_URL_LOCAL = 'http://localhost:9090/api/';
export const BASE_URL_DEV = 'https://dev.preppednplated.com/api/api/';
export const BASE_URL_PROD = 'https://preppednplated.com/api/api/';

//* login related endpoints */
export const GENERATE_TOKEN = 'user/generateToken';
export type GENERATE_TOKEN_PAYLOAD = {
  emailOrPhone: string;
  password: string;
};
export const GET_USER_DETAILS = 'user/getUserDetails';
export const FATCH_USERDETAILS_BY_ID_ENDPOINT = 'user/getuserdetailsbyid/';
export const FATCH_CUSTOMER_DETAILS = 'user/getCustomerList';
export const GET_CUSTOMER_DETAILS = 'user/getuserdetailsbyid/<cus_id>';
export const SEARCH_CUSTOMER_ENDPOINT = 'user/getCustomerList';
export type SEARCH_CUSTOMER_PAYLOAD = {
  firstName?: string;
  emailAddress?: string;
  phoneNo?: string;
  page?: number;
  size?: number;
};

//** Product related endpoints */
//! user prefix on below endpoints must be changed after the service fixup
export const FETCH_ALL_CATEGORIES_ENDPOINT = 'products/getCategoryList';
export const DELETE_CATEGORY_ENDPOINT = 'user/deleteCategory?catId=<cat_id>';
export const CHANGE_CATEGORY_ENDPOINT =
  'user/changeCategoryStatus?catId=<cat_id>&status=<status_code>';
export type ADD_CATEGORY_PAYLOAD = {
  categoryName: string;
  status: string;
  isDeleted: number;
};
export const ADD_CATEGORY_ENDPOINT = 'user/addCategory';
export const ADD_DISH_ENDPOINT = 'user/addDish';
export const FEATCH_ALL_DISHTYPE_ENDPOINT = 'user/getDishList';
export const DELETE_DISH_ENDPOINT = 'user/deleteDish?dishId=<dish_id>';
export const CHANGE_DISH_STATUS_ENDPOINT =
  'user/changeDishStatus?dishId=<dish_id>&status=<status_code>';

export type ADD_DISH_PAYLOAD = {
  dishTypeName: string;
  status: string;
  isDeleted: number;
};

export const ADD_MEAL_ENDPOINT = 'user/addMeal';
export const FATCH_ALLMEAL_ENDPOINT = 'user/getMealList';
export const DELETE_MEAL_ENDPOINT = 'user/deleteMeal?mealId=<meal_id>';
export const CHANGE_MEAL_STATUS_ENDPOINT =
  'user/changeMealStatus?mealId=<meal_id>&status=<status_code>';
export type ADD_MEALPAYLOAD = {
  mealTypeName: string;
  status: string;
  isDeleted: number;
};

export const ADD_WEEKLYMENU_ENDPOINT = 'user/addweeklymenu';
export const FETCH_ALL_WEEKLY_MENU_ENDOINT = 'user/getWeeklyMenulist';
export const FETCH_ALL_ACTIVE_WEEKLY_MENU_ENDOINT =
  'user/getActiveWeeklyMenulist';
export const DELETE_WEEKLY_MENU_ENDPOINT =
  'user/deleteweeklyMenu?menuId=<menu_Id>';
export const CHANGE_WEEKLY_MENU_STATUS_ENDPOINT =
  'user/changeWeeklyMenuStatus?menuId=<menu_id>&status=<status_code>';
export type ADD_WEEKLY_MENU_PAYLOAD = {
  weeklyMenu: String;
  isDeleted: number;
  status: string;
  weekDay: string;
  deadline_day: string;
};

export type SEARCH_ITEMS_PAYLOAD = {
  itemName?: string;
  categoryName?: string;
  mealTypeName?: string;
  dishTypeName?: string;
  page?: number;
  size?: number;
};

export const SEARCH_ITEMS_ENDPOINT = 'products/items';

export type ADD_ITEM_PAYLOAD = {
  itemId?: string;
  itemName: string;
  new: boolean;
  category: any;
  mealType: any;
  dishType: any;
  price: string;
  orderInstructions: string;
  reheatInstructions: string;
  carbs: string;
  fat: string;
  calories: string;
  protein: string;
};
export const ADD_ITEM_ENDPOINT = 'products/items/add';
export const DELETE_ITEM_ENDPOINT = 'products/items/delete?itemId=<item_id>';

export const GET_ITEM_ENDPOINT = 'products/items/<item_slug>';
export const CHANGE_ITEM_STATUS_ENDPOINT =
  'products/items/changeStatus?itemId=<item_id>&status=<status_code>';

export type FORM_DATA_UPLOAD_ITEM_FILE = {
  file: File;
  fileId?: string;
  formCode: string;
  mediaType: string;
};
export const UPLOAD_ITEM_FILE = 'fe/uploadSingleFileInFolder';

export const FORGET_PASSWORD_ENDPOINT = 'user/sendForgetPasswordMailLink';
export const FATCH_EMAIL_BY_TOKEN_ENDPOINT = 'user/findEmailByToken/';
export const UPDATE_PASSWORD_BY_ID = 'user/updatepasswordbyid';
export type RESET_PASSWORD_PAYLOAD = {
  id: number;
  password: string;
  newpassword: string;
};
export const RESET_PASSWORD_ENDPOINT = 'user/resetpasswordbyId';

export const GET_ALL_ACTIVE_MENU_DETAILS_ENDPOINT =
  'products/activeMenuDetails';
export type CREATE_MENU_ITEM_PAYLOAD = {
  menuDetails: any;
  items: any[];
};
export const CREATE_MENU_ITEM_ENDPOINT = 'products/menuitem';
export const GET_CHOSEN_ITEMS = 'products/chosenItems?id=<menu_detail_id>';
export const GET_REMAINING_ITEMS =
  'products/remainingItems?id=<menu_detail_id>';

export type SEARCH_ORDERS_PAYLOAD = {
  orderId?: string;
  customerName?: string;
  customerPhone?: string;
  orderStatus?: string;
  orderStartDate?: string;
  orderDeliveryStartDate?: string;
  orderDeliveryEndDate?: string;
  orderEndDate?: string;
  page?: number;
  size?: number;
  coolerBagRequired?: boolean;
};

export const SEARCH_ORDERS_ENDPOINT = 'orders';
export const FETCH_ALL_ORDER_STATUS = 'orders/status';
export const FETCH_ORDER_BY_ID = 'orders/<order_id>';
export const CHANGE_ORDER_STATUS =
  'orders/changeStatus?orderId=<order_id>&orderStatus=<order_status>';

export const GENERATE_EXPORT_EXCEL_ENDPOINT = 'orders/export/<menu_option_id>';
export const DOWNLOAD_MEDIA_ENDPOINT = 'fe/downloadMedia/<file_name>';

export const GET_ACTIVE_MENU_OPTIONS = 'user/getActiveWeeklyMenulist';
export const GET_ACTIVE_CATEGORIES_ENDPOINT = 'products/getActiveCategoryList';
export const GET_ACTIVE_DISH_TYPES_ENDPOINT = 'user/getActiveDishTypeList';
export const GET_ACTIVE_MEAL_TYPES_ENDPOINT = 'user/getActiveMealList';

export const GET_ALL_LOCATION_ENDPOINT = 'picklocation/getallpickuplocation';
export const ADD_PICKUP_LOCATION_ENDPOINT = 'picklocation/add';
export const DELETE_BY_ID_LOCATION_ENDPOINT = 'picklocation/delete/';
export const CHANGE_STATUS_ENDPOINT = 'picklocation/changestatus';
export type CHANGE_STATUS_PAYLOAD = {
  id: number;
  status: string;
};
export type ADD_LOCATION_PAYLOAD = {
  location_name: string;
  street1: string;
  street2: string;
  city: String;
  state: String;
  postal_code: String;
  is_deleted: number;
};
export const SEND_CUSTOM_MAIL_ENDPOINT = 'orders/sendmail';
export type SEND_MAIL_PAYLOAD = {
  mailTo: String;
  firstName: string;
  mailDtl: String;
};
export type SEARCH_DeliveryZone_PAYLOAD = {
  zip_code?: string;
  weekly_option_id?: string;
  page?: number;
  size?: number;
};
export const FETCH_ALL_DELIVERY_ZONES_ENDPOINT_PAGINATION =
  'zipcode/fetchallzipcodelist';
export const FETCH_ALL_DELIVERY_ZONES_ENDPOINT = 'zipcode/fetchallzipcode';
export const DELETE_DELEVERY_ZONES_ENDPOINT = 'zipcode/deletezip/';
export const ADD_DELIVERY_ZONES_ENDPOINT = 'zipcode/add';
export type ADD_DELEVERY_ZONE_PAYLOAD = {
  zip_code: string;
  weekly_option_id: any;
  delivery_amount: string;
  tax_rate: string;
};
export const update_Bag_Management_ENDPOINT = 'orders/updatecoolerbagreturn/';

export const GENERATE_ORDER_ROUTING_EXCEL_ENDPOINT =
  'orders/generateOrderRoutingInstructions?date=<delivery_date>&fileType=<type>';
export const GENERATE_ORDER_LABEL_EXCEL_ENDPOINT =
  'orders/generateOrderForm?date=<delivery_date>&fileType=<type>';

export const GENERATE_ORDER_INVOICE_ENDPOINT =
  'pdfcontroller/downloadOrderPDF?startDate=<delivery_date>';

export const GENERATE_ORDER_INVOICEPDF_ENDPOINT =
  'pdfcontroller/downloadPDF?orderId=<order_id>';

export const GEN_CUSTOMER_LIST = 'orders/generateCustomerList';

/************************* Menu Related **********************************************/
export const FETCH_ALL_MENU_STATUS = 'menu/status';
export const FETCH_MENU_DETAILS_BY_ID = 'menu/details/<id>';
export const FETCH_MENU_DEADLINE_DATE =
  'menu/deadlineDate?menuOptionSlug=<menu_option_slug>&deliveryDate=<delivery_date>';
export const FETCH_ALL_MENU_DETAILS = 'menu';
export const FETCH_MENU_DETAILS_BY_MENU_OPTION_AND_DELIVERY_DATE =
  'menu/details?menuOption=<menu_option_slug>&deliveryDate=<delivery_date>';
export const FETCH_MENU_ITEMS_BY_MENU_OPTION_AND_DELIVERY_DATE =
  'menu/items?menuOption=<menu_option_slug>&deliveryDate=<delivery_date>';
export const CREATE_MENU = 'menu/create';
export const FETCH_CHOSEN_ITEMS = 'menu/chosenItems?detail=<id>';
export const FETCH_REMAINING_ITEMS = 'menu/remainingItems?detail=<id>';
export const PUBLISH_MENU = 'menu/publish/<menu_option_id>/<details_id>';
export const DELETE_MENU = 'menu/details/<id>';
export const FETCH_PUBLISHED_MENU_BY_MENU_OPTION =
  'menu/details/getPublishedMenu?menuOption=<menu_option_slug>';

/********************* Coupon Related ***********************************************/
export const FETCH_COUPONS = 'coupon/fetchCoupons';
export const FETCH_COUPON_BY_CODE = 'coupon/<code>';
export const ADD_COUPONS = 'coupon/addCoupon';
export const CHANGE_COUPON_STATE =
  'coupon/toggleActiveState?state=<active_state>&couponId=<coupon_id>';

/*********************** Subscription Related **************************************/
export const SAVE_CARD = 'cards/saveCard';
export const FETCH_CARDS_BY_CUSTOMER_ID_OR_USER_ID =
  'cards?customerId=<customer_id>&userId=<user_id>';
export const FETCH_CARDS_BY_USER_ID = 'cards?userId=<userId>';
