import { Injectable } from '@angular/core';
import {
  GET_ADDRESS_TYPES,
  MAKE_DEFAULT_ADDRESS,
  PAYLOAD_MAKE_DEFAULT_ADDRESS,
  PAYLOAD_UPDATE_USER_ADDRESS,
  PAYLOAD_UPDATE_USER_INFO,
  SAVE_CUSTOMER_ADDRESS,
  UPDATE_USER_INFO_ENDPOINT,
} from '../constants';
import {
  DELETE_ADDRESS_ENDPOINT_ALT,
  FETCH_SIMILAR_IEMS_BY_MENU_OPTION,
  FETCH_SIMILAR_ITEMS,
} from './../constants';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private httpService: HttpService) {}

  updateUserInfo(payload: PAYLOAD_UPDATE_USER_INFO) {
    return this.httpService.post(UPDATE_USER_INFO_ENDPOINT, payload);
  }
  updateUserAddress(payload: PAYLOAD_UPDATE_USER_ADDRESS) {
    return this.httpService.post(SAVE_CUSTOMER_ADDRESS, payload);
  }
  makeAddressDefault(payload: PAYLOAD_MAKE_DEFAULT_ADDRESS) {
    return this.httpService.post(MAKE_DEFAULT_ADDRESS, payload);
  }

  getAddressTypes() {
    return this.httpService.get(GET_ADDRESS_TYPES);
  }

  deleteAddress(id: string) {
    return this.httpService.post(
      DELETE_ADDRESS_ENDPOINT_ALT.replace('<address_id>', id),
      {}
    );
  }

  fetchSimilarItems() {
    return this.httpService.get(FETCH_SIMILAR_ITEMS);
  }
  fetchSimilarItemsByMenuOption(menuOption: string) {
    return this.httpService.get(
      FETCH_SIMILAR_IEMS_BY_MENU_OPTION.replace('<slug>', menuOption)
    );
  }
}
