import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Models } from '@pnp/shared';
import { Observable } from 'rxjs';
import { selectCartState } from './../store/root.selector';

@Injectable({
  providedIn: 'root',
})
export class CheckoutGuard implements CanActivate {
  cart$!: Observable<Models.Schemas.CartVo>;
  cart: Partial<Models.Schemas.CartVo> | undefined;
  constructor(private store: Store, private router: Router) {
    this.cart$ = this.store.select(selectCartState);
    this.cart$.subscribe((data) => {
      this.cart = data;
      console.log('Checkout guard subs: ', this.cart);
    });
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      !this.cart ||
      !this.cart.cartItems ||
      Object.keys(this.cart).length === 0 ||
      this.cart.cartItems.length === 0
    ) {
      return this.router.parseUrl('/cart');
    }
    return true;
  }
}
