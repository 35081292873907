import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelify',
})
export class CamelifyPipe implements PipeTransform {
  transform(value: string, delimeter: string): string | null | undefined {
    if (!value) return value;

    const splitSentence = value.split(delimeter);

    let finalSentence = '';
    splitSentence.map((item, idx) => {
      finalSentence +=
        item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() + ' ';
    });

    return finalSentence;
  }
}
