<div>
    <div class="flex flex-wrap">
        <p-selectButton [options]="options" class="mr-3 gratuity-selection-btn" (onOptionClick)="calculateOutput()"
            optionLabel="field" [(ngModel)]="gratuityStatus">
        </p-selectButton>
    </div>
    <div *ngIf="gratuityStatus.value === -1" class="mt-3 p-2 flex justify-content-between align-items-center ">
        <div class="w-full">
            <p-inputNumber class="w-full" [(ngModel)]="customGratuity" currency="USD" mode="currency"> </p-inputNumber>
        </div>
        <div class="pl-2">
            <button pButton label="Apply" class="p-button-outlined" (click)="sendCustomGratuityAmount()"></button>
        </div>
    </div>
</div>