import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { Loading } from 'notiflix';
import { MessageService } from 'primeng/api';
import {
  catchError,
  concatMap,
  EMPTY,
  Observable,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { AlreadyExistsError } from 'src/app/shared/errors/AlreadyExistsError';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ICart, ICartItem, ResponseError } from 'src/app/shared/types';
import { CartItemService } from './../../user/cart/service/cartitem.service';
import { InitialSharedState, ResponseType, SharedState } from './shared.state';

@Injectable({
  providedIn: 'root',
})
export class SharedStore extends ComponentStore<SharedState> {
  constructor(
    private authService: AuthService,
    private cartItemService: CartItemService,
    private messageService: MessageService,
    private router: Router
  ) {
    super(InitialSharedState);
    console.log('Shared Store initialized');
  }

  user$ = this.select((state) => state.user);
  cartItems$ = this.select((state) => state.cartItems);
  cartItemsCount$ = this.select((state) => state.cartItemsCount);

  readonly updateUser = this.updater((state, value: ResponseType) => ({
    ...state,
    user: value,
  }));
  readonly updateCartItems = this.updater((state, value: ResponseType) => ({
    ...state,
    cartItems: value,
  }));
  readonly updateCartItemsCount = this.updater(
    (state, value: number | null) => ({
      ...state,
      cartItemsCount: value,
    })
  );
  readonly patchCartError = this.updater((state, value: any) => ({
    ...state,
    cartItems: { ...state.cartItems, errorData: value },
  }));

  readonly reset = () => this.setState(InitialSharedState);

  readonly subscribeTo = this.effect(($) => $);
  readonly fetchUserDetails = this.effect((trigger$) => {
    return trigger$.pipe(
      tap((_) =>
        this.updateUser({ data: null, loading: true, success: false })
      ),
      switchMap((id) =>
        this.authService.getUserDetails().pipe(
          tap({
            next: (res: any) =>
              this.updateUser({
                data: res.data,
                loading: false,
                success: true,
              }),
          })
        )
      )
    );
  });
  readonly fetchCartItems = this.effect((userId$: Observable<string>) => {
    return userId$.pipe(
      tap((_) => {
        Loading.circle();
        this.updateCartItems({ data: null, loading: true, success: false });
      }),
      switchMap((id) =>
        this.cartItemService.fetchCatItemsApi(id).pipe(
          tap({
            next: (res: any) => {
              const bagTotal = this.calculateCartTotal(res.data.cartItems);

              this.updateCartItems({
                data: { ...res.data, bagTotal },
                loading: false,
                success: true,
              });

              this.updateCartItemsCount(
                this.countCartItems(res.data.cartItems)
              );
              Loading.remove();
            },
          }),
          catchError((err) => {
            this.updateCartItems({
              data: null,
              loading: false,
              success: false,
            });
            this.updateCartItemsCount(null);
            Loading.remove();
            return EMPTY;
          })
        )
      )
    );
  });

  readonly saveCartItems = this.effect((payload$: Observable<ICart>) => {
    return payload$.pipe(
      tap((_: any) =>
        this.updateCartItems({
          data: [],
          loading: true,
          success: false,
        })
      ),
      switchMap((payload) =>
        this.cartItemService.fetchCatItems(payload).pipe(
          tap({
            next: (res: any) => {
              this.updateCartItems({
                data: res.data,
                loading: false,
                success: true,
              });

              // this.getCartItems(payload.userVo.id);
              // this.updateGetCartItems({ data: [], code: '', message:'', loading: false, success: false })
              // setTimeout(() => {
              // this.updateGetCartItems({ data: res.data, code: res.status, message: res.message, loading: false, success: true });
              // }, 200);
            },
          }),
          catchError((err) => {
            this.updateCartItems({
              data: [],
              loading: false,
              success: false,
            });
            return EMPTY;
          })
        )
      )
    );
  });
  readonly saveAndFetchCartItems = this.effect(
    (
      payload$: Observable<ICart & Partial<{ msgKey: string; msg: string }>>
    ) => {
      return payload$.pipe(
        tap((_: any) => {
          Loading.circle();
          this.updateCartItems({
            data: [],
            loading: true,
            success: false,
          });
        }),
        switchMap((payload) =>
          //
          this.cartItemService.fetchCatItems(payload).pipe(
            concatMap((res) =>
              this.cartItemService.fetchCartByUserId(payload.userVo.id)
            ),
            tap({
              next: (res: any) => {
                const bagTotal = this.calculateCartTotal(res.data.cartItems);
                this.updateCartItems({
                  data: { ...res.data, bagTotal },
                  loading: false,
                  success: true,
                });

                this.updateCartItemsCount(
                  this.countCartItems(res.data.cartItems)
                );

                if (payload.msgKey && payload.msg) {
                  this.messageService.clear();
                  this.messageService.add({
                    key: payload.msgKey,
                    severity: 'success',
                    summary: payload.summary,
                    detail: payload.msg,
                    life: 5000,
                  });
                }
                Loading.remove();
              },
              error: (err: ResponseError) => {
                console.log('Cart Error: ', err);

                if (
                  err.code === 406 &&
                  err.message.toLowerCase().includes('invalid local cart')
                ) {
                  localStorage.removeItem('localCart');
                  this.router.navigateByUrl('/cart');
                }

                if (err.code === 406)
                  throwError(() => new AlreadyExistsError(err.message));
                throwError(() => new Error(err.message));
              },
            }),
            catchError((err: ResponseError) => {
              this.updateCartItems({
                data: [],
                loading: false,
                success: false,
                error: true,
                errorData: err,
              });
              this.updateCartItemsCount(null);
              Loading.remove();

              return EMPTY;
            })
          )
        )
      );
    }
  );
  readonly saveAndFetchCartItemsAfterLogin = this.effect(
    (
      payload$: Observable<ICart & Partial<{ msgKey: string; msg: string }>>
    ) => {
      return payload$.pipe(
        tap((_: any) => {
          Loading.circle();
          this.updateCartItems({
            data: [],
            loading: true,
            success: false,
          });
        }),
        switchMap((payload) =>
          //
          this.cartItemService.fetchCatItems(payload).pipe(
            concatMap((res) =>
              this.cartItemService.fetchCartByUserId(payload.userVo.id)
            ),
            tap({
              next: (res: any) => {
                const bagTotal = this.calculateCartTotal(res.data.cartItems);
                this.updateCartItems({
                  data: { ...res.data, bagTotal },
                  loading: false,
                  success: true,
                });

                this.updateCartItemsCount(
                  this.countCartItems(res.data.cartItems)
                );

                if (res.data.cartItems.length === 0)
                  this.router.navigate(['/cart']);
                else this.router.navigate(['/checkout']);

                if (payload.msgKey && payload.msg) {
                  this.messageService.clear();
                  this.messageService.add({
                    key: payload.msgKey,
                    severity: 'success',
                    summary: payload.summary,
                    detail: payload.msg,
                    life: 5000,
                  });
                }
                Loading.remove();
              },
            }),
            catchError((err: ResponseError) => {
              this.updateCartItems({
                data: [],
                loading: false,
                success: false,
                error: true,
                errorData: err,
              });
              this.updateCartItemsCount(null);
              Loading.remove();

              return EMPTY;
            })
          )
        )
      );
    }
  );

  readonly removeAndFetchCart = this.effect(
    (itemId$: Observable<string | number>) => {
      return itemId$.pipe(
        tap({
          next: (_) => {
            this.updateCartItems({ data: null, loading: true, success: false });
            this.updateCartItemsCount(null);
            Loading.circle();
          },
        }),
        switchMap((itemId) =>
          this.cartItemService.deleteCartItemApi(itemId).pipe(
            concatMap((res) =>
              this.cartItemService.fetchCart().pipe(
                tap({
                  next: (res: any) => {
                    this.updateCartItems({
                      data: res.data,
                      loading: false,
                      success: true,
                    });
                    this.updateCartItemsCount(
                      this.countCartItems(res.data.cartItems)
                    );
                    Loading.remove();
                  },
                }),
                catchError((err: any) => {
                  this.updateCartItems({
                    data: null,
                    loading: false,
                    success: false,
                  });
                  this.updateCartItemsCount(null);
                  Loading.remove();
                  return EMPTY;
                })
              )
            )
          )
        )
      );
    }
  );
  private calculateCartTotal(cartItems: ICartItem[]): number | null {
    let total = 0;
    cartItems.map((item) => (total += +item.itemPrice * +item.itemQty));
    return total;
  }
  private countCartItems(cartItems: ICartItem[]): number | null {
    let count: number = 0;
    cartItems.map((item) => {
      count += +item.itemQty;
    });
    return count === 0 ? null : count;
  }
}
