import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Models } from '@pnp/shared';
import { Loading } from 'notiflix';
import { MessageService } from 'primeng/api';
import {
  EMPTY,
  catchError,
  concatMap,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CartItemService } from 'src/app/user/cart/service/cartitem.service';
import { MenuService } from 'src/app/user/menu/service/menu.service';
import { GlobalCartService } from '../services/global-cart/cart.service';
import { UserService } from '../services/user.service';
import { CartApiService } from './../services/global-cart/cart-api.service';
import { RootActions } from './root.action';
import { selectBasicInfo } from './root.selector';
@Injectable()
export class RootEffects {
  constructor(
    private actions$: Actions,
    private cartApiService: CartApiService,
    private cartService: GlobalCartService,
    private authService: AuthService,
    private userService: UserService,
    private globalCartService: GlobalCartService,
    private menuService: MenuService,
    private cartItemService: CartItemService,
    private store: Store,
    private messageService: MessageService
  ) {}

  loadUser = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.userFetch),
      tap({ next: (_) => Loading.circle() }),
      switchMap((action) =>
        this.authService.getUserDetails().pipe(
          map((res: any) => {
            Loading.remove();
            return RootActions.userFetchSuccessful({ user: res.data });
          }),
          catchError((err: any) => {
            Loading.remove();
            RootActions.userFetchFailure();
            return EMPTY;
          })
        )
      )
    )
  );

  loadUserById = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.fetchUserById),
      tap({ next: (_) => Loading.circle() }),
      switchMap((payload) =>
        this.authService.getUserDetailsById(payload.id).pipe(
          map((res: any) => {
            Loading.remove();
            return RootActions.fetchUserByIdSuccessful({ user: res.data });
          }),
          catchError((err: any) => {
            Loading.remove();
            RootActions.userFetchFailure();
            return EMPTY;
          })
        )
      )
    )
  );

  loadCart = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RootActions.cartFetch,
        RootActions.addToCartSuccess,
        RootActions.cartItemQtyChangedSuccess,
        RootActions.cartItemRemoved
      ),
      map(() => {
        const payloadObj = {
          id:
            localStorage.getItem('customerId') !== null
              ? localStorage.getItem('customerId')
              : localStorage.getItem('id'),
        };
        return RootActions.cartFetchRemote({ id: payloadObj.id });
      })
    )
  );
  addToCart = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.addToCart),
      tap((_) => Loading.circle()),
      map((action) => {
        const payload: Partial<Models.Schemas.CartInputDto> = {
          ...action.payload,
          item: action.payload.item,
          userId: Number(this.authService.getAuthenticatedUserId()),
        };
        return { action: { ...action }, payload: { ...payload } };
      }),
      concatMap((combinedActionPayload) =>
        this.cartApiService.addToCart(combinedActionPayload.payload).pipe(
          map(() => {
            Loading.remove();
            this.messageService.add({
              key: combinedActionPayload.action.successMsgBoxKey,
              detail: combinedActionPayload.action.successMsg,
              severity: 'success',
              life: 4000,
            });
            return RootActions.addToCartSuccess();
          }),
          catchError((err) => {
            Loading.remove();
            if (err.code === 403) {
              combinedActionPayload.action?.errorCallback(
                combinedActionPayload.action.payload.item || {},
                err.message,
                combinedActionPayload.action.dialogBoxKey
              );
            }
            return throwError(() => err);
          })
        )
      )
    )
  );

  modifyCartItemQty = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.cartItemQtyChanged),
      tap((_) => Loading.circle()),
      concatMap((action) =>
        this.cartApiService.modifyCartItemQty(action.payload).pipe(
          map(() => {
            Loading.remove();
            return RootActions.cartItemQtyChangedSuccess();
          }),
          catchError((err) => {
            Loading.remove();
            return EMPTY;
          })
        )
      )
    )
  );
  removeCartItem = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.removeCartItem),
      tap((_) => Loading.circle()),
      map((action) => {
        const payload: Partial<Models.Schemas.RemoveCartItemInputDto> = {
          ...action.payload,
          user: Number(this.authService.getAuthenticatedUserId()),
        };
        return payload;
      }),
      concatMap((payload) =>
        this.cartApiService.removeCartItem(payload).pipe(
          map(() => {
            Loading.remove();
            return RootActions.cartItemQtyChangedSuccess();
          }),
          catchError((err) => {
            Loading.remove();
            return EMPTY;
          })
        )
      )
    )
  );

  fetchMenuOptions = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.menuOptionsFetch),
      concatMap((action) =>
        this.menuService
          .getAllActiveWeeklyMenu()
          .pipe(
            map((res: any) =>
              RootActions.menuOptionsFetchSuccessful({ menuOptions: res.data })
            )
          )
      )
    )
  );

  loadCartRemote = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.cartFetchRemote),
      concatMap((payload) =>
        this.cartItemService.fetchCartByUserId(payload.id).pipe(
          map((res: any) =>
            RootActions.cartFetchSuccessful({ cart: res.data })
          ),
          catchError((err) => EMPTY)
        )
      )
    )
  );

  fetchDeliveryAddressTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.fetchDeliveryAddressTypes),
      tap((_) => Loading.circle()),
      switchMap((action) =>
        this.userService.getAddressTypes().pipe(
          map((res: any) => {
            Loading.remove();
            return RootActions.fetchDeliveryAddressTypesSuccess({
              addressTypes: res.data,
            });
          }),
          catchError((err) => {
            Loading.remove();
            return EMPTY;
          })
        )
      )
    )
  );

  fetchSimilarItems = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.fetchSimilarItems),
      tap((_) => Loading.circle()),
      switchMap((action) =>
        this.userService.fetchSimilarItems().pipe(
          map((res: any) => {
            Loading.remove();
            return RootActions.fetchSimilarItemsSuccess({
              similarItems: res.data,
            });
          }),
          catchError((err) => {
            Loading.remove();
            return EMPTY;
          })
        )
      )
    )
  );
  fetchSimilarItemsByMenuOption = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.fetchSimilarItemsByMenuOption),
      tap((_) => Loading.circle()),
      switchMap((action) =>
        this.userService.fetchSimilarItemsByMenuOption(action.menuOption).pipe(
          map((res: any) => {
            Loading.remove();
            return RootActions.fetchSimilarItemsSuccess({
              similarItems: res.data,
            });
          }),
          catchError((err) => {
            Loading.remove();
            return EMPTY;
          })
        )
      )
    )
  );

  loadStripeCustomer = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.fetchStripeCustomer),
      // withLatestFrom(this.store.select(selectBasicInfo)),
      switchMap((action: any) =>
        this.authService.getStripeCustomer(action.userId).pipe(
          // map(() => {
          //   console.log('basicInfo--> ', basicInfo);
          //   return null;
          // }),
          mergeMap((stripeCustomerRes:any) =>
            iif(
              () => stripeCustomerRes.status === 404 && stripeCustomerRes.data === null,
              this.authService.saveStripeCustomer(action.userId).pipe(
                map((stripeCustomerIDRes: any) => {
                  return RootActions.fetchStripeCustomerSuccessful({ stripeCustomer: stripeCustomerIDRes.data });
                }),
                catchError((error: any) => EMPTY)
              ),
              of(RootActions.fetchStripeCustomerSuccessful({ stripeCustomer: stripeCustomerRes.data }))
            )
            //  {return of(RootActions.doNothing())}
          ),
          catchError((error: any) => EMPTY)
        )
      )
    )
  );

  userLoggedOut = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.userLoggedOut),
      map((_) => {
        localStorage.clear();
        return RootActions.doNothing();
      })
    )
  );

  scrollToHtml = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.scrollToHtmlElement),
      map((action) => {
        document
          .querySelector(`#${action.refId}`)
          ?.scrollIntoView({ behavior: 'smooth' });
        return RootActions.doNothing();
      })
    )
  );
}
