//******** Cart Data Types **********/
export enum enumIsDeleted {
  N = 'N',
  Y = 'Y',
}

export type ICartItem = {
  id?: string | number;
  mainItemId: string | null;
  itemName: string;
  itemSlug: string | null;
  categoryName: string;
  mealTypeName: string;
  dishTypeName: string;
  itemPic: string;
  itemPrice: string | number;
  itemQty: string | number;
};

export type ICart = {
  id?: number;
  cartItems: ICartItem[];
  menuOption: { id: string | number; weekDay: string };
  deliveryDate: Date;
  deliveryFee: string | number;
  coolerBagAmount: string | number;
  coolerBagRequired: boolean;
  userVo: { id: string | number | null };
  isDeleted: enumIsDeleted;
  instructions?: string;
};

export type IMenuOption = {
  id: string | number;
  weeklyMenuName: string;
  slug: string;
  weekDay: string;
  deadline_day: string;
  isDeleted: number;
  delivery_date: Date;
  status: string;
};

export type ResponseError = {
  code: number;
  message: string;
};

export type ISquareCheckoutPayload = {
  checkout_options: {
    allow_tipping: boolean;
    ask_for_shipping_address: boolean;
    accepted_payment_methods: {
      google_pay: boolean;
      apple_pay: boolean;
    };
    redirect_url: string;
  };
  idempotency_key: string;
  order: {
    location_id: string;
    line_items: [
      {
        quantity: string;
        item_type: 'ITEM' | 'CUSTOM_AMOUNT' | 'GIFT_CARD';
        base_price_money: {
          currency: 'USD' | 'INR';
          amount: number;
        };
        name: string;
      }
    ];
  };
};
export interface MenuItem {
  id: number;
  itemId: number;
  itemName: string;
  slug: string;
  categoryName: string;
  mealTypeName: string;
  dishTypeName: string;
  weeklyMenuName: string;
  menuOptionId: number;
  fileUrl: string;
  price: string;
  isNew: number;
  menuOptionSlug: string;
}
