import { Injectable } from '@angular/core';
import { Models } from '@pnp/shared';
import {
  CART_ITEMS_ENDPOINT,
  GET_CART_ITEMS_ENDPOINT_ALT,
  MODIFY_CART_ITEM_QTY,
  REMOVE_CART_ITEM,
} from '../../constants';
import { ICart } from '../../types';
import { HttpService } from '../http.service';
@Injectable({ providedIn: 'root' })
export class CartApiService {
  constructor(private httpService: HttpService) {}
  saveCart(payload: ICart) {
    return this.httpService.post(CART_ITEMS_ENDPOINT, payload);
  }
  addToCart(payload: Partial<Models.Schemas.CartInputDto>) {
    return this.httpService.post(CART_ITEMS_ENDPOINT, payload);
  }

  fetchCart() {
    return this.httpService.get(GET_CART_ITEMS_ENDPOINT_ALT);
  }

  modifyCartItemQty(payload: Partial<Models.Schemas.CartItemInputDto>) {
    return this.httpService.post(MODIFY_CART_ITEM_QTY, payload);
  }

  removeCartItem(payload: Partial<Models.Schemas.RemoveCartItemInputDto>) {
    return this.httpService.post(REMOVE_CART_ITEM, payload);
  }
}
