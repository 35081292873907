<section>


    <div class="body">
        <span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </span>
        <div class="base">
            <span></span>
            <div class="face"></div>
        </div>
    </div>
    <!-- <div class="longfazers">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div> -->
    <h1>Redirecting to the payment gateway. Please Wait....</h1>


</section>