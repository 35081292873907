import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export enum enumHttpMethods {
  'GET',
  'POST',
}
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private client: HttpClient, private router: Router) {}
  // baseUrl = BASE_URL_PROD;
  baseUrl = environment.baseUrl;

  get(url: string) {
    return this.client.get(this.baseUrl + url);
  }

  post(url: string, payload: any) {
    return this.client.post(this.baseUrl + url, payload);
  }

  fileUploadRequest(url: string, formData: FormData) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.client.post(this.baseUrl + url, formData, { headers: headers });
  }
  fileDownloadRequest(url: string) {
    return this.client.get(this.baseUrl + url, { responseType: 'blob' });
  }
}
