import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { RootActions } from 'src/app/shared/store/root.action';
import { SharedStore } from 'src/app/shared/store/shared-store.service';
import { HttpService } from './shared/services/http.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'user';
  componentDestroyed$$ = new Subject<boolean>();
  userId: string | null = null;
  isBrowser: any;
  isServer: any;
  customerId: string | null;

  constructor(
    private sharedStore: SharedStore,
    private httpService: HttpService,
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
    this.userId = localStorage.getItem('id');
    this.customerId = localStorage.getItem('customerId') || null;
  }

  ngOnInit(): void {
    //* if a user is logged in pull every relevant data to shared store for app wide usage

    const userRole = localStorage.getItem('role');

    if (this.userId) {
      this.store.dispatch(RootActions.userFetch());
      this.store.dispatch(RootActions.cartFetch());
      this.sharedStore.fetchUserDetails();
      this.sharedStore.fetchCartItems(
        this.customerId === null
          ? localStorage.getItem('id') || ''
          : localStorage.getItem('customerId') || ''
      );
      
    }
    
    if (this.customerId) {
      this.store.dispatch(RootActions.fetchUserById({ id: this.customerId }));
    }

    this.sharedStore.cartItems$.subscribe((data) => {
      console.log('Cart Data Global: ', data);
    });
    this.callCartItemsData(
      this.customerId === null
        ? localStorage.getItem('id') || ''
        : localStorage.getItem('customerId') || ''
    );
  }

  user$ = this.sharedStore.user$;
  localCart: any = null;
  cart: any = null;

  callCartItemsData(userId: any) {
    this.cart = JSON.parse(localStorage.getItem('localCart') || 'null');

    console.log('global cart: ', this.cart);
    if (userId != null && this.cart != null) {
      this.sharedStore.updateCartItems({
        data: this.cart,
        loading: false,
        success: false,
      });
    } else if (userId == null && this.cart != null) {
      this.sharedStore.updateCartItems({
        data: this.cart,
        loading: false,
        success: false,
      });

      if (this.cart?.cartItems.length > 0) {
        this.countLocalCartItems();
      }
    } else if (userId !== null && this.localCart == null) {
      this.callCartDetailApi(userId);
    }
  }
  callCartDetailApi(userId: any) {
    // this.cartStore.getCartItems(userId)

    // Added by Kousik
    if (userId) {
      console.log('appcomp->userId: ', userId);

      this.httpService
        .get(`cart/getItemsByUser?userId=${userId}`)
        .subscribe((res: any) => {
          this.cart = res.data;
          this.sharedStore.updateCartItems(res.data);

          if (this.cart.cartItems?.length > 0) {
            this.countLocalCartItems();
          }
          // this.cartService.getQuantity(this.cartMst.cartItems?.length);
        });
    }
  }
  countLocalCartItems() {
    console.log('global cart: ', this.localCart);

    if (this.cart.cartItems.length > 0) {
      let cartNumber = this.cart.cartItems
        .map((item: any) => item.itemQty)
        .reduce((prev: any, next: any) => +prev + +next);
      this.sharedStore.updateCartItemsCount(cartNumber);
    } else {
      this.sharedStore.updateCartItemsCount(0);
    }
  }
  ngOnDestroy() {
    this.componentDestroyed$$.next(true);
    this.componentDestroyed$$.complete();
  }
}
