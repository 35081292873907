import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRootState } from './root.state';

export const selectRootState = createFeatureSelector<IRootState>('root');
export const selectCartState = createSelector(
  selectRootState,
  (state) => state.cart
);
export const selectCartMenuOptionSlug = createSelector(
  selectCartState,
  (state) => state?.menuOption?.slug
);

export const selectMenuOptionsState = createSelector(
  selectRootState,
  (state) => state.menuOptions
);
export const selectSundayMenuSlug = createSelector(
  selectMenuOptionsState,
  (state) => {
    if (state.length > 0) return state[0].slug;
    return '';
  }
);

export const selectUserAddresses = createSelector(
  selectRootState,
  (state) => state.user.address
);

export const selectCustomerAddresses = createSelector(
  selectRootState,
  (state) => state.customer.address
);

export const selectCartItems = createSelector(selectCartState, (state) =>
  !state ? [] : state.cartItems
);

export const selectCartCount = createSelector(selectCartItems, (state) => {
  if (!state) return 0;
  const count = state.reduce(
    (total, item) => (total += +(item.itemQty || 0)),
    0
  );
  return count;
});

export const selectCartSubtotal = createSelector(selectCartItems, (state) => {
  if (!state) return 0;
  const total = state.reduce(
    (total, item) => (total += +(item.itemQty || 0) * +(item.itemPrice || 0)),
    0
  );
  return total;
});

export const selectAddressTypes = createSelector(
  selectRootState,
  (state) => state.addressTypes
);

export const selectUserState = createSelector(
  selectRootState,
  (state) => state.user
);

export const selectUserByIdState = createSelector(
  selectRootState,
  (state) => state.customer
);

export const selectBasicInfo = createSelector(selectUserState, (state) => ({
  id:state.id,
  firstName: state.firstName,
  lastName: state.lastName,
  emailAddress: state.emailAddress,
  phoneNo: state.phoneNo,
}));

export const selectSimilarItems = createSelector(
  selectRootState,
  (state) => state.similarItems
);

export const selectStripeCustomer = createSelector(
  selectRootState,
  (state) => state.stripeCustomer
);
