import { createAction, props } from '@ngrx/store';
import { Models } from '@pnp/shared';
import { IUserModel } from './models/user.model';
import { enumRootActionTypes } from './rootActions.type';

export const RootActions = {
  doNothing: createAction(enumRootActionTypes.DO_NOTHING),
  scrollToHtmlElement: createAction(
    enumRootActionTypes.SCROLL_TO_HTML_ELEMENT,
    props<{ refId: string }>()
  ),
  userFetch: createAction(enumRootActionTypes.USER_FETCH),
  userFetchSuccessful: createAction(
    enumRootActionTypes.USER_FETCH_SUCCESSFUL,
    props<{ user: IUserModel }>()
  ),

  fetchUserById: createAction(
    enumRootActionTypes.FETCH_USER_BY_ID,
    props<{ id: string }>()
  ),
  fetchUserByIdSuccessful: createAction(
    enumRootActionTypes.FETCH_USER_SUCCESSFUL_BY_ID,
    props<{ user: IUserModel }>()
  ),

  userFetchFailure: createAction(enumRootActionTypes.USER_FETCH_FAILURE),

  cartFetch: createAction(enumRootActionTypes.CART_FETCH),
  cartFetchLocal: createAction(enumRootActionTypes.CART_FETCH_LOCAL),
  cartFetchRemote: createAction(
    enumRootActionTypes.CART_FETCH_REMOTE,
    props<{ id: any }>()
  ),
  cartFetchSuccessful: createAction(
    enumRootActionTypes.CART_FETCH_SUCCESSFUL,
    props<{ cart: Models.Schemas.CartVo }>()
  ),
  cartFetchFailure: createAction(enumRootActionTypes.CART_FETCH_FAILURE),
  addToCart: createAction(
    enumRootActionTypes.ADD_TO_CART,
    props<{
      payload: Partial<Models.Schemas.CartInputDto>;
      errorCallback: (
        item: Models.Schemas.CartItemsVo,
        message: string,
        msgBoxKey: string
      ) => void;
      dialogBoxKey: string;
      successMsg: string;
      successMsgBoxKey: string;
    }>()
  ),
  addToCartSuccess: createAction(enumRootActionTypes.ADD_TO_CART_SUCCESS),
  cartItemQtyChanged: createAction(
    enumRootActionTypes.CART_ITEM_QTY_CHANGED,
    props<{ payload: Partial<Models.Schemas.CartItemInputDto> }>()
  ),
  cartItemQtyChangedSuccess: createAction(
    enumRootActionTypes.CART_ITEM_QTY_CHANGE_SUCCESS
  ),
  removeCartItem: createAction(
    enumRootActionTypes.REMOVE_CART_ITEM,
    props<{ payload: Partial<Models.Schemas.RemoveCartItemInputDto> }>()
  ),
  cartItemRemoved: createAction(enumRootActionTypes.CART_ITEM_REMOVED),
  differentCartAlreadyExists: createAction(
    enumRootActionTypes.DIFFERENT_CART_ALREADY_EXISTS
  ),
  menuOptionsFetch: createAction(enumRootActionTypes.MENU_OPTIONS_FETCH),
  menuOptionsFetchSuccessful: createAction(
    enumRootActionTypes.MENU_OPTIONS_FETCH_SUCCESSFUL,
    props<{ menuOptions: any }>()
  ),
  menuOptionsFetchFailure: createAction(
    enumRootActionTypes.MENU_OPTIONS_FETCH_FAILURE
  ),

  fetchSimilarItems: createAction(enumRootActionTypes.GET_SIMILAR_ITEMS),

  fetchSimilarItemsByMenuOption: createAction(
    enumRootActionTypes.GET_SIMILAR_ITEMS_BY_MENU_OPTION,
    props<{ menuOption: string }>()
  ),
  fetchSimilarItemsSuccess: createAction(
    enumRootActionTypes.GET_SIMILAR_ITEMS_SUCCESS,
    props<{ similarItems: any[] }>()
  ),

  fetchDeliveryAddressTypes: createAction(
    enumRootActionTypes.FETCH_DELIVERY_ADDRESS_TYPES
  ),
  fetchDeliveryAddressTypesSuccess: createAction(
    enumRootActionTypes.FETCH_DELIVERY_ADDRESS_TYPES_SUCCESS,
    props<{ addressTypes: any[] }>()
  ),
  userLoggedOut: createAction(enumRootActionTypes.USER_LOGGED_OUT),
  
  fetchStripeCustomer: createAction(enumRootActionTypes.GET_STRIPE_CUSTOMER,
    props<{ userId: string }>()),

    fetchStripeCustomerSuccessful: createAction(
    enumRootActionTypes.STRIPE_CUSTOMER_FETCH_SUCCESSFUL,
    props<{ stripeCustomer: any }>()
  ),
};
