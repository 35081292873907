export enum enumRootActionTypes {
  'DO_NOTHING' = '[Root] Do nothing.',
  'SCROLL_TO_HTML_ELEMENT' = '[Shared] scroll to html element.',
  'USER_FETCH' = '[Shared] Logged in user fetch',
  'USER_FETCH_SUCCESSFUL' = '[Shared] Logged in user fetch successful',

  //Admin Order
  'FETCH_USER_BY_ID' = '[Shared] Logged in fetch user by ID',
  'FETCH_USER_SUCCESSFUL_BY_ID' = '[Shared] Logged in fetch user by ID successful',

  'USER_FETCH_FAILURE' = '[Shared] Logged in user fetch failure',
  'FETCH_USER_ADDRESS_TYPES' = '[Shared] Fetch user address types',
  'MENU_OPTIONS_FETCH' = '[Shared] Menu options fetch',
  'MENU_OPTIONS_FETCH_SUCCESSFUL' = '[Shared] Menu options fetch successful',
  'MENU_OPTIONS_FETCH_FAILURE' = '[Shared] Menu options fetch successful',
  'CART_FETCH' = '[Shared] Cart fetch',
  'CART_FETCH_SUCCESSFUL' = '[Shared] Cart fetch successful',
  'CART_FETCH_FAILURE' = '[Shared] Cart fetch failure',
  'CART_FETCH_LOCAL' = '[Shared] Cart fetch local',
  'CART_FETCH_REMOTE' = '[Shared] Cart fetch remote',
  'ADD_TO_CART' = '[Shared] Add to cart.',
  'ADD_TO_CART_SUCCESS' = '[Shared] Add to cart success.',
  'CART_ITEM_QTY_CHANGED' = '[Shared] Cart item qty changed',
  'CART_ITEM_QTY_CHANGE_SUCCESS' = '[Shared] Cart item qty change success.',
  'REMOVE_CART_ITEM' = '[Shared] Remove cart item.',
  'CART_ITEM_REMOVED' = '[Shared] Removed cart item.',
  'DIFFERENT_CART_ALREADY_EXISTS' = '[Shared] Different cart already exits.',
  'FETCH_DELIVERY_ADDRESS_TYPES' = '[Shared] Fetch Delivery address types',
  'FETCH_DELIVERY_ADDRESS_TYPES_SUCCESS' = '[Shared] Fetch Delivery address types success',
  'GET_SIMILAR_ITEMS' = '[Shared] Fetch similar items',
  'GET_SIMILAR_ITEMS_BY_MENU_OPTION' = '[Shared] Fetch similar items by menu option',
  'GET_SIMILAR_ITEMS_SUCCESS' = '[Shared] Fetch similar success',
  'NO_ACTION' = '[Shared] Do Nothing',
  'USER_LOGGED_OUT' = '[Shared] User logged out.',
  'GET_STRIPE_CUSTOMER' = "[Shared] Fetch Stripe Customer",
  'STRIPE_CUSTOMER_FETCH_SUCCESSFUL' = "[Shared] Fetch Stripe Customer Successfull"
}
