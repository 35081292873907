import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  standalone: true,
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  constructor(private router: Router) {}
  ngOnInit(): void {
    this.prohibitComingBackFromPayment();
  }

  prohibitComingBackFromPayment() {
    // listen for changes in the browser history
    window.onpopstate = (event) => {
      // check if the current URL is the Stripe checkout page URL
      if (window.location.href.includes('/checkout')) {
        console.log('Window event: ', event);
        // redirect the user to the cart page
        this.router.navigate(['/cart']);
      }
    };
  }
}
