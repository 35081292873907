import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthService } from 'src/app/shared/services/auth.service';

import { CartService } from 'src/app/user/menu/service/cart.service';

import { SharedStore } from './../../../../shared/store/shared-store.service';

import { MenuStore } from './../../../../user/menu/store/menu-store.service';

@Component({
  selector: 'user-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [MenuStore],
})
export class HeaderComponent implements OnInit {
  islogin!: boolean;
  userId: any;
  localCart: any;
  cartMst: any;

  // cartItemDetails$ = this.cartStore.cartItems$;
  cartItemDetails$ = this.sharedStore.cartItems$;
  constructor(
    public authservice: AuthService,
    private cartService: CartService,
    private router: Router,
    private menuStore: MenuStore,
    private sharedStore: SharedStore,
    private store: Store
  ) {
    this.islogin = this.authservice.islogin();
  }

  itemQty: any = 0;
  activeMenuOptions$ = this.menuStore.activeMenuOptions$;
  cartItemsCount$ = this.sharedStore.cartItemsCount$;
  user$ = this.sharedStore.user$;

  ngOnInit(): void {
    this.userId = JSON.parse(localStorage.getItem('id') || 'null');
    console.log(this.userId);
    console.log(localStorage.getItem('quantity'));

    this.user$.subscribe((data) => {
      if (JSON.parse(localStorage.getItem('id') || 'null')) {
        this.islogin = true;
      }
    });

    this.menuStore.fetchActiveMenuOptions();
  }

  logout() {
    this.authservice.logoutUser();
    this.sharedStore.reset();
    this.router.navigate(['/auth/login']);
  }

  userprofile() {
    if (this.islogin == true) {
      this.router.navigate(['profile']); //**********it will change after adding userprofile page*****************
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  orderHistory() {
    if (this.islogin == true) {
      this.router.navigate(['order-history']); //**********it will change after adding orderpage page*****************
    } else {
      this.router.navigate(['/auth/login']);
    }
  }
}
