<p-dialog [visible]="addressDialogVisible" [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
    [style]="{ width: '50vw' }" position="center" [modal]="true" closeIcon="none">
    <ng-template pTemplate="header">
        <h4>Update Address</h4>
    </ng-template>
    <div class="grid" [formGroup]="addressForm">
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">Street Line 1 <span class="text-red-500">*</span></label>
            <input type="text" pInputText placeholder="Enter Street Line 1" class="w-full" formControlName="street1" />
            <div
                *ngIf="addressForm.controls['street1'].invalid && (addressForm.controls['street1'].dirty || addressForm.controls['street1'].touched)">
                <span class="text-red-500">Street Line 1 is required.</span>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">Street Line 2</label>
            <input type="text" pInputText placeholder="Enter Street Line 2" class="w-full" formControlName="street2" />
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">City <span class="text-red-500">*</span></label>
            <input type="text" pInputText placeholder="Enter City" class="w-full" formControlName="city" />
            <div
                *ngIf="addressForm.controls['city'].invalid && (addressForm.controls['city'].dirty || addressForm.controls['city'].touched)">
                <span class="text-red-500">City is required.</span>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">Postal Code <span class="text-red-500">*</span></label>
            <p-inputNumber placeholder="Enter Postal Code" formControlName="postal_code" class="postal-input"
                [maxlength]="5" [format]="false"></p-inputNumber>

            <div
                *ngIf="addressForm.controls['postal_code'].invalid && (addressForm.controls['postal_code'].dirty || addressForm.controls['postal_code'].touched)">
                <span class="text-red-500">Postal Code is required.</span>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">State <span class="text-red-500">*</span></label>
            <input type="text" pInputText placeholder="Enter State" class="w-full" formControlName="state" />
            <div
                *ngIf="addressForm.controls['state'].invalid && (addressForm.controls['state'].dirty || addressForm.controls['state'].touched)">
                <span class="text-red-500">State is required.</span>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">Country</label>

            <input pInputText placeholder="Enter Country" class="w-full readonly-field" formControlName="country"
                readonly />
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">Please provide helpful delivery information here.<span class="text-red-500">*</span></label>
            <textarea id="float-input" rows="1" cols="5" pInputTextarea placeholder="Enter Delivery Instructions"
                formControlName="order_instruction"></textarea>
            <div
                *ngIf="addressForm.controls['order_instruction'].invalid && (addressForm.controls['order_instruction'].dirty || addressForm.controls['order_instruction'].touched)">
                <span class="text-red-500">Order Instruction is required.</span>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="">Address Type <span class="text-red-500">*</span></label>
            <p-dropdown [options]="addressTypes" optionLabel="name" formControlName="ref_id"
                placeholder="Select Address Type" appendTo="body" styleClass="w-full"></p-dropdown>
            <div
                *ngIf="addressForm.controls['ref_id'].invalid && (addressForm.controls['ref_id'].dirty || addressForm.controls['ref_id'].touched)">
                <span class="text-red-500">Address Type is required.</span>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton label="Cancel" class="p-button-outlined mx-3" (click)="setDialogInvisible()"></button>
        <button pButton label="Save Address" (click)="sendOutAddress()" icon="pi pi-save" [loading]="loadingState"
            [disabled]="!addressForm.valid"></button>
    </ng-template>
</p-dialog>