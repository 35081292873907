import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PAYLOAD_UPDATE_USER_INFO } from './../../constants';

@Component({
  selector: 'app-basic-info-dialog',
  templateUrl: './basic-info-dialog.component.html',
  styleUrls: ['./basic-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicInfoDialogComponent {
  @Input('updateBasicInfoCallback') updateAddressCallback!: () => void;
  @Input('visibility') dialogVisible!: boolean;
  @Input('input') input?: PAYLOAD_UPDATE_USER_INFO;
  @Output('output')
  output!: EventEmitter<PAYLOAD_UPDATE_USER_INFO>;
  @Output('toggleVisibility') toggleVisibility!: EventEmitter<boolean>;

  infoForm!: FormGroup;
  constructor(private formBuilder: FormBuilder) {
    this.toggleVisibility = new EventEmitter<boolean>();
    this.output = new EventEmitter<PAYLOAD_UPDATE_USER_INFO>();
  }

  ngOnInit(): void {
    this.initInfoForm();
  }
  initInfoForm() {
    this.infoForm = this.formBuilder.group({
      emailAddress: [''],
      firstName: [''],
      lastName: [''],
      phoneNo: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.input) this.patchInfoForm();
  }
  patchInfoForm() {
    this.infoForm.patchValue({
      emailAddress: this.input?.emailAddress,
      firstName: this.input?.firstName,
      lastName: this.input?.lastName,
      phoneNo: this.input?.phoneNo,
    });
  }
  setDialogInvisible() {
    this.toggleVisibility.emit(false);
  }

  sendOutInfo() {
    let formValue = this.infoForm.value;
    this.output.emit(formValue);
  }
}
