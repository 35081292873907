import { EventEmitter, Injectable } from '@angular/core';
import { Models } from '@pnp/shared';
import { ICartItem } from './../../../shared/types';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  $isCartData = new EventEmitter();

  constructor() {}

  getQuantity(qty: any) {
    console.log('Cart Service');
    this.$isCartData.emit(qty);
  }

  convertItemToCartItem(item: any): ICartItem {
    let cartItem = <ICartItem>{
      mainItemId: item.itemId,
      itemName: item.itemName,
      itemPrice: item.price,
      itemSlug: item.slug,
      itemQty: item.qty || 1,
      categoryName: item.categoryName,
      mealTypeName: item.mealTypeName,
      dishTypeName: item.dishTypeName,
      itemPic: item.fileUrl,
    };
    return cartItem;
  }
}
