import { Injectable } from '@angular/core';
import {
  ADD_GRATUITY,
  CART_ITEMS_ENDPOINT,
  DELETE_CART_ITEMS_ENDPOINT,
  GET_CART_ITEMS_ENDPOINT,
  GET_CART_ITEMS_ENDPOINT_ALT,
  HttpService,
  ICart,
  PAYLOAD_ADD_GRATUITY,
} from '@prepped-n-plated/shared';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CartItemService {
  // getItemDetailsbyId(id: any) {
  //   return this.httpService.get(FETCH_ITEMS_BYID_ENDOINT+id);
  // }

  private addMsg$$!: BehaviorSubject<string | null>;

  constructor(private httpService: HttpService) {
    this.addMsg$$ = new BehaviorSubject<string | null>(null);
  }

  setAddMessage(message: string) {
    this.addMsg$$.next(message);
  }

  getAddCartMessage() {
    return this.addMsg$$.value;
  }
  fetchCatItems(payload: ICart) {
    return this.httpService.post(CART_ITEMS_ENDPOINT, payload);
  }
  saveCart(payload: ICart) {
    return this.httpService.post(CART_ITEMS_ENDPOINT, payload);
  }

  fetchCatItemsApi(userId: string) {
    return this.httpService.get(
      GET_CART_ITEMS_ENDPOINT.replace('<user_id>', userId)
    );
  }
  fetchCartByUserId(userId: string | number) {
    console.log('Fetch cart new service: ');

    return this.httpService.get(
      GET_CART_ITEMS_ENDPOINT.replace('<user_id>', <string>userId)
    );
  }
  fetchCart() {
    return this.httpService.get(GET_CART_ITEMS_ENDPOINT_ALT);
  }

  deleteCartItemApi(itemId: string | number) {
    return this.httpService.get(
      DELETE_CART_ITEMS_ENDPOINT.replace('<item_id>', <string>itemId)
    );
  }

  addGratuity(payload: PAYLOAD_ADD_GRATUITY) {
    return this.httpService.post(ADD_GRATUITY, payload);
  }
}
